import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'
import Select, { components } from 'react-select';
import moment from 'moment';
import swal from 'sweetalert';
import { Modal, Tab, Tabs } from "react-bootstrap";
import { Upload, Button, List, Table, Alert } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';

import { useTheme } from "./theme-provider.js"
import { getCookie } from '../common_component/cookie.js';
import PageError from "../common_component/PageError.js";
import StatusBadge from "../common_component/StatusBadge.js";
import formatNumber from "../common_component/formatNumber.js";
import { useAuthData } from "./auth-provider.js"

function CreateGstSubmission() {
    const [state, setstate] = useState(2);
    const { theme, dropdownStyleDark } = useTheme()

    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
    const [previewModalShow, setPreviewModalShow] = useState(false);
    const [externalPreviewModalShow, setExternalPreviewModalShow] = useState(false);
    const [itemsModalShow, setItemsModalShow] = useState(false)
    const [externalItemsModalShow, setExternalItemsModalShow] = useState(false)
    const [itemsData, setItemsData] = useState([]);
    const [activeTab, setActiveTab] = useState("Invoices");
    const [invoiceType, setInvoiceType] = useState("Customer");

    const invoiceTypeList = ["Customer", "Ecommerce"];

    const getRecentMonth = (monthsAgo = 0) => {
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - monthsAgo);
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Ensure 2 digits
        return `${year}-${month}`;
    };

    const [filingMonth, setFilingMonth] = useState(getRecentMonth())
    const [outwardSummary, setOutwardSummary] = useState(null);
    const [totalInternalTransaction, setTotalInternalTransaction] = useState(0);
    const [totalUploadedTransaction, setTotalUploadedTransaction] = useState(0);
    const [organizationGstinList, setOrganizationGstinList] = useState([]);
    const [gstinFilingSettings, setGstinFilingSettings] = useState(null)
    const [selectedOrganizationGstin, setSelectedOrganizationGstin] = useState(null)

    const [fileData, setFileData] = useState(null);
    const [sheetsInfo, setSheetsInfo] = useState([]);
    const [errors, setErrors] = useState([]);
    const [errModalShow, setErrModalShow] = useState(false);
    const [uploadedData, setUploadedData] = useState(null);

    const [externalDataUploadModalShow, setExternalDataUploadModalShow] = useState(false);
    const [externalDataSubmitButtonState, setExternalDataSubmitButtonState] = useState(false);

    var page_name = "create-gst-submission";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let formatNumberOptions = {
        removeTrailingZeros: true
    }

    async function getOrganizationGstinList() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-gst-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            const default_gstin = body.data.find(x => x.default_gstin == 1);
            setOrganizationGstinList(body.data);
            getGstinFilingSettings(default_gstin.unique_id);
            setSelectedOrganizationGstin({ label: default_gstin.GstIn, value: default_gstin.unique_id, legal_name: default_gstin.legal_name, trade_name: default_gstin.trade_name })
        }
    }

    async function getGstinFilingSettings(gstin) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-gstin-filing-settings`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "gstin": gstin, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            if (body.data) {
                setGstinFilingSettings(JSON.parse(body.data));
            }
            else {
                setGstinFilingSettings(null)
            }
        }
    }

    async function getGstinOutwardSummary(filing_month, gstin) {
        setTotalInternalTransaction(0);
        setOutwardSummary(null);
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-gstin-outward-summary`, { method: 'POST', body: JSON.stringify({ "email": user.email, "filing_month": filing_month, "gstin": gstin, "page_name": page_name, "flag": "collection_list", "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation !== "success") throw "get gstin outward summary api error"

        let totalInternalTransactionData = [...body.data.ecommerce_invoices, ...body.data.customer_invoices, ...body.data.credit_notes]
        setTotalInternalTransaction(totalInternalTransactionData.length);
        setOutwardSummary(body.data);
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            getOrganizationGstinList();
        }
    }, [user, visibility, read, write])

    useEffect(() => {
        if (filingMonth != "" && selectedOrganizationGstin !== null) {
            setstate(2);
            let p1 = getGstinOutwardSummary(filingMonth, selectedOrganizationGstin.value);
            Promise.all([p1])
                .then(async (val) => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)

                })
        }
    }, [filingMonth, selectedOrganizationGstin])

    useEffect(() => {
        if (sheetsInfo.length > 0) {
            const invoices = sheetsInfo.find(x => x.name == 'Invoices').data;
            const invoiceItems = sheetsInfo.find(x => x.name == 'Invoice Items').data;
            invoices.forEach((obj, i) => {
                obj.item_array = invoiceItems.filter(x => x.invoice_number == obj.invoice_number);
            });
            setUploadedData({ invoices: invoices, credit_notes: [] });
            setTotalUploadedTransaction(invoices.length);
        }
        else {
            setUploadedData(null);
            setTotalUploadedTransaction(0);
        }
    }, [sheetsInfo])

    const formatDate = (inputDate) => {
        // Split the input string
        const [year, month] = inputDate.split('-');

        // Create a date object (JavaScript months are 0-indexed, so subtract 1 from month)
        const date = new Date(year, month - 1);

        // Get the full month name
        const monthName = date.toLocaleString('default', { month: 'long' });

        // Return formatted string
        return `${monthName} - ${year}`;
    };

    const createTransaction = async (type) => {
        if (gstinFilingSettings == null) {
            swal("Oops!", "Filing settings are not configured for the selected GSTIN.", "warning");
            return false
        }
        const object = new FormData()
        const gstin_object = {};
        gstin_object.gstin_id = selectedOrganizationGstin.value;
        gstin_object.gstin_number = selectedOrganizationGstin.label;
        object.append("filing_month", filingMonth);
        object.append("filing_type", type);
        object.append("filing_data", JSON.stringify(outwardSummary));
        object.append("file", fileData);
        object.append("sheets_info", JSON.stringify(sheetsInfo));
        object.append("gstin_details", JSON.stringify(gstin_object));
        object.append("organization_id", organization_id);
        object.append("page_name", page_name)

        setMainSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": 'multipart/form-data',
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/save-gstin-filing-details`, object, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Filing details saved successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const getNextGSTR1FilingDate = () => {
        // Get today's date
        const today = moment();

        // Get the 11th of next month
        const nextMonth = today.add(1, "month").startOf("month").date(11);

        // If today is past 11th, use next month's date, otherwise, this month's date
        return nextMonth.format("ll");
    };

    const validateSheets = (sheets) => {
        const validationErrors = [];
        const invoiceNumbers = new Set();
        sheets.forEach((sheet) => {
            sheet.data.forEach((row, rowIndex) => {
                Object.keys(row).forEach((key) => {
                    const structuredKey = key.replace(/_/g, ' ') // Replace underscores with spaces
                        .split(' ') // Split the string into words
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
                        .join(' '); // Join the words back into a single string
                    // Mandatory Field checking
                    if (!row[key]) {
                        validationErrors.push({
                            sheetName: sheet.name,
                            row: rowIndex + 1,
                            field: structuredKey,
                            message: `Mandatory Field.`,
                        });
                    }
                    // Should be positive number checking
                    if ((key == "cgst_amnt" || key == "igst_amnt" || key == "sgst_amnt" || key == "tax_rate" || key == "taxable_value" || key == "grand_total") && (typeof row[key] !== 'number' || row[key] < 0)) {
                        validationErrors.push({
                            sheetName: sheet.name,
                            row: rowIndex + 1,
                            field: structuredKey,
                            message: `Should be positive number.`,
                        });
                    }

                    // Duplicate invoice number checking
                    if (sheet.name == "Invoices" && key == "invoice_number") {
                        if (invoiceNumbers.has(row[key])) {
                            validationErrors.push({
                                sheetName: sheet.name,
                                row: rowIndex + 1,
                                field: structuredKey,
                                message: `Duplicate invoice number.`,
                            });
                        }
                        else {
                            invoiceNumbers.add(row[key]);
                        }
                    }
                    // GSTIN format checking
                    const gstinPattern = /^[0-9A-Z]{15}$/;
                    if (sheet.name == "Invoices" && key == "gstin_number" && !gstinPattern.test(row[key])) {
                        validationErrors.push({
                            sheetName: sheet.name,
                            row: rowIndex + 1,
                            field: structuredKey,
                            message: `Invalid GSTIN format.`,
                        });
                    }
                    // Add more validation logic here for specific fields
                });
            });
        });
        setErrors(validationErrors);
        if (validationErrors.length > 0) {
            setErrModalShow(true);
            return false;
        }
        return true;
    };

    const handleFileChange = ({ file }) => {
        if (!(file instanceof Blob)) {
            setFileData(null);
            setSheetsInfo([]);
        }
        else {
            const reader = new FileReader();
            reader.onload = (e) => {
                const binaryStr = e.target.result;
                const workbook = XLSX.read(binaryStr, { type: 'binary' });
                const sheets = workbook.SheetNames.map((sheetName) => {
                    const worksheet = workbook.Sheets[sheetName];
                    const actualData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                    const modifiedData = extractSheetData(actualData, sheetName); // Removes the last row if it's extra data
                    return { name: sheetName, actualData: actualData, data: modifiedData, rowCount: modifiedData.length }; // excluding header
                });
                if (validateSheets(sheets)) {
                    setSheetsInfo(sheets);
                    setFileData(file);
                }
            };
            reader.readAsArrayBuffer(file);
        }
    };

    // Function to extract sheet data while ignoring last column and blank rows
    function extractSheetData(data, sheetName) {
        if (sheetName === 'Invoices') {
            data = data.map(row => {
                // Exclude the last column by slicing object entries
                const entries = Object.entries(row).slice(0, -1);
                return Object.fromEntries(entries);
            }).filter(row => {
                // Remove blank rows
                return Object.values(row).some(value => value !== null && value !== '');
            });
        }

        //const keys = Object.values(data[0]);
        const result = data.slice(1).map(row => {
            if (sheetName === 'Invoices') {
                return {
                    invoice_type: row[0],
                    gstin_number: row[1],
                    invoice_number: row[2],
                    invoice_date: row[3],
                    grand_total: row[4],
                    place_of_supply: row[5],
                    supply_type: row[6]
                };
            }
            else if (sheetName === 'Invoice Items') {
                return {
                    invoice_number: row[0],
                    tax_rate: row[1],
                    taxable_value: row[2],
                    cgst_amnt: row[3],
                    sgst_amnt: row[4],
                    igst_amnt: row[5]
                };
            }
        });
        return result;
    }

    // Group errors by sheet name for easy display
    const groupedErrors = errors.reduce((acc, error) => {
        acc[error.sheetName] = acc[error.sheetName] || [];
        acc[error.sheetName].push(error);
        return acc;
    }, {});

    return (
        <>
            <div>
                <div className="d-flex flex-stack">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">GST Filings</h1>
                </div>
                <div>
                    {
                        state === 1 ?
                            <>
                                <div className="row m-2">
                                    <div className='col-md-3'>
                                        <input type="month" className="month-input" onChange={(e) => {
                                            setFilingMonth(e.target.value);
                                        }} value={filingMonth} />
                                    </div>
                                    <div className='col-md-5'>
                                        <Select
                                            options={organizationGstinList.map(x => { return { label: x.GstIn, value: x.unique_id, legal_name: x.legal_name, trade_name: x.trade_name } })}
                                            onChange={(val) => {
                                                getGstinFilingSettings(val.value);
                                                setSelectedOrganizationGstin(val);
                                            }}
                                            value={selectedOrganizationGstin}
                                            placeholder="Select GSTIN..."
                                            styles={theme == 'dark' ? dropdownStyleDark : {}}
                                            components={{
                                                Option: (props) => {
                                                    return (
                                                        <components.Option  {...props}>
                                                            <span className='fw-bold'>{props.children}</span>
                                                            <div className='d-flex justify-content-between'>
                                                                <span className="fs-6">{props.data.legal_name}</span>
                                                                <span className="fs-6">{props.data.trade_name}</span>
                                                            </div>
                                                        </components.Option >
                                                    )
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                {
                                    gstinFilingSettings ?
                                        <div className="card mb-5 mb-xl-8">
                                            <div className="card-header border-0 pt-5">
                                                <h3 className="card-title align-items-start flex-column">
                                                    <span className="card-label fw-bold fs-3 mb-1">GSTR-1 ({formatDate(filingMonth)}) </span>
                                                    <span className="text-muted mt-1 fw-semibold fs-7">Details of outward supplies of goods and services</span>
                                                </h3>
                                                <div className="card-toolbar">
                                                    <button className='btn btn-primary rounded ' data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} onClick={() => { setExternalDataUploadModalShow(true) }}>
                                                        <span className="indicator-label"><i className="las la-file-upload" style={{ fontSize: "32px" }}></i>Upload Data</span>
                                                        <span className="indicator-progress">Please wait...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="card-body py-3">
                                                {outwardSummary !== null ?
                                                    <div className="table-responsive">
                                                        <table className="table align-middle table-row-bordered table-row-solid gy-4 gs-9">
                                                            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
                                                                <tr>
                                                                    <th className="min-w-100px">Total Transaction</th>
                                                                    <th className="min-w-100px">Type</th>
                                                                    <th className="min-w-100px">Filing Date</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="fw-6 fw-semibold text-gray-600">
                                                                <tr>
                                                                    <td>
                                                                        <a onClick={(e) => {
                                                                            if (totalInternalTransaction > 0) {
                                                                                setPreviewModalShow(true);
                                                                            }
                                                                        }} className="text-hover-primary text-gray-600 cursor-pointer">{totalInternalTransaction} Transactions</a>
                                                                    </td>
                                                                    <td>Internal</td>
                                                                    <td>{getNextGSTR1FilingDate(filingMonth)}</td>
                                                                </tr>
                                                                {sheetsInfo.length > 0 &&
                                                                    <tr>
                                                                        <td>
                                                                            <a onClick={(e) => { setExternalPreviewModalShow(true); }} className="text-hover-primary text-gray-600 cursor-pointer">{totalUploadedTransaction} Transactions</a>
                                                                        </td>
                                                                        <td>External</td>
                                                                        <td>{getNextGSTR1FilingDate(filingMonth)}</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    :
                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed  p-6">
                                                        <i className="ki-duotone ki-shield-tick fs-2tx text-primary me-4"><span className="path1"></span><span className="path2"></span></i>
                                                        <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                                            <div className="mb-3 mb-md-0 fw-semibold">
                                                                <h4 className="text-gray-900 fw-bold">Secure Your Account</h4>
                                                                <div className="fs-6 text-gray-700 pe-7">For this month, the GSTIN data has already been filed.</div>
                                                            </div>
                                                            <Link to="/" className="btn btn-primary px-6 align-self-center text-nowrap">Summary</Link>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            {
                                                (totalInternalTransaction > 0 || sheetsInfo.length > 0) &&
                                                <div className="card-footer d-flex justify-content-end py-6 px-9">
                                                    <button className='btn btn-success rounded' data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} onClick={() => { createTransaction("GSTR-1") }}>
                                                        <span className="indicator-label">Save</span>
                                                        <span className="indicator-progress">Please wait...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                        :    
                                        <PageError message='Please go to GST Settings and update the Online Filing Settings for the selected GSTIN No. before proceeding.' showReloadMsg={true}>
                                            <Link className='btn btn-primary rounded' to={`/gst-settings`}>GST Settings</Link>
                                        </PageError>
                                }
                            </>
                            :
                            state === 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <PageError />
                    }
                </div>
            </div >

            <Modal show={errModalShow} onHide={() => { setErrModalShow(false) }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title as="h2">Error List</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setErrModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        {Object.keys(groupedErrors).map((sheetName) => (
                            <div key={sheetName} style={{ marginBottom: '20px' }}>
                                <Alert message={`Errors in ${sheetName}`} type="error" showIcon />
                                <Table
                                    dataSource={groupedErrors[sheetName]}
                                    rowKey={(record) => `${record.row}-${record.field}`}
                                    columns={[
                                        { title: 'Row', dataIndex: 'row', key: 'row' },
                                        { title: 'Field', dataIndex: 'field', key: 'field' },
                                        { title: 'Message', dataIndex: 'message', key: 'message' },
                                    ]}
                                    pagination={true}
                                    style={{ marginTop: '10px' }}
                                />
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setErrModalShow(false) }}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={externalDataUploadModalShow} onHide={() => { setExternalDataUploadModalShow(false) }} centered>
                <Modal.Header>
                    <Modal.Title as="h2">Add External Data in Filing</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setExternalDataUploadModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="d-flex justify-content-between mb-5">
                            <div className='col-6 my-auto'>
                                <label className="fs-5 fw-bold my-2">Download Excel file template:</label>
                            </div>
                            <div className='col-6 my-auto'>
                                <button className='btn btn-info btn-lg rounded' data-kt-indicator={externalDataSubmitButtonState ? "on" : "off"} disabled={externalDataSubmitButtonState} ><i className="las la-file-download" style={{ fontSize: "32px" }}></i>
                                    <span className="indicator-label">Download</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className='col-6 my-auto'>
                                <label className="fs-5 fw-bold my-2">Select an Excel file with formatted data:</label>
                            </div>
                            <div className='col-6 my-auto'>
                                <Upload
                                    beforeUpload={() => false} // Prevent auto upload
                                    onChange={handleFileChange}
                                    maxCount={1}
                                    accept=".xlsx,.xls"
                                >
                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                            </div>
                        </div>
                        {sheetsInfo.length > 0 && (
                            <div style={{ marginTop: 20 }}>
                                <h3>File Preview:</h3>
                                <List
                                    bordered
                                    dataSource={sheetsInfo}
                                    renderItem={(item) => (
                                        <List.Item>Sheet: {item.name} - Rows: {item.rowCount}</List.Item>
                                    )}
                                />
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setExternalDataUploadModalShow(false) }}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={itemsModalShow} onHide={() => { setItemsModalShow(false); }} centered size="md" contentClassName='border border-primary'>
                <Modal.Header className='py-2'>
                    <Modal.Title>Items</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setItemsModalShow(false); setItemsData([]); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div style={{ marginBottom: '20px' }}>
                            <Table
                                dataSource={itemsData}
                                rowKey={(record) => `${record.row}-${record.field}`}
                                columns={[
                                    { title: 'Item Name', dataIndex: 'item_name', key: 'item_name' },
                                    { title: 'Quantity', dataIndex: 'quantity', key: 'quantity' }
                                ]}
                                pagination={true}
                                style={{ marginTop: '10px' }}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={externalItemsModalShow} onHide={() => { setExternalItemsModalShow(false); }} size="lg" contentClassName='border border-primary'>
                <Modal.Header className='py-2'>
                    <Modal.Title>Items</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setExternalItemsModalShow(false); setItemsData([]); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className='overflow-auto w-100 flex-grow-1'>
                            <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }}>
                                <thead className='bg-light-info fs-4 fw-bold text-primary'>
                                    <tr className="fw-bold text-muted">
                                        <th className="min-w-150px"> Tax Rate </th>
                                        <th className="min-w-150px"> Taxable Value </th>
                                        <th className="min-w-150px"> CGST Amount </th>
                                        <th className="min-w-150px"> SGST Amount </th>
                                        <th className="min-w-150px"> IGST Amount </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        itemsData.map((obj, indx) => {
                                            return (
                                                <tr key={indx}>
                                                    <td><span className="text-dark fw-bold text-hover-primary mb-1 fs-6">{obj.tax_rate}</span></td>
                                                    <td><span className="text-dark fw-bold text-hover-primary mb-1 fs-6">{obj.taxable_value}</span></td>
                                                    <td><span className="text-dark fw-bold text-hover-primary mb-1 fs-6">{obj.cgst_amnt}</span></td>
                                                    <td><span className="text-dark fw-bold text-hover-primary mb-1 fs-6">{obj.sgst_amnt}</span></td>
                                                    <td><span className="text-dark fw-bold text-hover-primary mb-1 fs-6">{obj.igst_amnt}</span></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={externalPreviewModalShow} onHide={() => { setExternalPreviewModalShow(false) }} centered dialogClassName='h-modal-80'>
                <Modal.Header>
                    <Modal.Title as="h2">Uploaded Data Preview</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setExternalPreviewModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="d-flex flex-column gap-7">
                            <Tabs
                                activeKey={activeTab}
                                onSelect={(k) => setActiveTab(k)}
                                variant="pills"
                            >
                                <Tab eventKey="Invoices" title="Invoices">
                                    <div className='overflow-auto w-100 flex-grow-1'>
                                        <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }}>
                                            <thead>
                                                <tr className="fw-bold text-muted">
                                                    <th className="min-w-150px">Buyer's Gstin</th>
                                                    <th className="min-w-150px">Invoice Number</th>
                                                    <th className="min-w-150px">Items</th>
                                                    <th className="min-w-150px">Invoice Date</th>
                                                    <th className="min-w-150px">Grand Total</th>
                                                    <th className="min-w-150px">Place of supply</th>
                                                    <th className="min-w-150px">Supply Type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    uploadedData !== null && uploadedData.invoices.map((obj, indx) => {
                                                        return (
                                                            <tr key={obj.invoice_number}>
                                                                <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.gstin_number}</span></td>
                                                                <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.invoice_number}</span></td>
                                                                <td><button className='btn btn-sm btn-info rounded' onClick={() => { setExternalItemsModalShow(true); setItemsData(obj.item_array) }}>View</button></td>
                                                                <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{moment(obj.invoice_date).format("ll")}</span></td>
                                                                <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.grand_total}</span></td>
                                                                <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.place_of_supply}</span></td>
                                                                <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.supply_type}</span></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Tab>
                                <Tab eventKey="Credit Note" title="Credit Note">
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setExternalPreviewModalShow(false) }}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={previewModalShow} onHide={() => { setPreviewModalShow(false) }} centered dialogClassName='h-modal-80'>
                <Modal.Header>
                    <Modal.Title as="h2">Data Preview</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setPreviewModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="d-flex flex-column gap-7">
                            <Tabs
                                activeKey={activeTab}
                                onSelect={(k) => setActiveTab(k)}
                                variant="pills"
                            >
                                <Tab eventKey="Invoices" title="Invoices">
                                    <div className="row m-2">
                                        <div className='col-md-5'>
                                            <Select
                                                options={invoiceTypeList.map(x => { return { label: x, value: x } })}
                                                onChange={(val) => {
                                                    setInvoiceType(val.value);
                                                }}
                                                value={{ label: invoiceType, value: invoiceType }}
                                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                            />
                                        </div>
                                    </div>
                                    {
                                        outwardSummary !== null && (invoiceType == "Customer" ? outwardSummary.customer_invoices : outwardSummary.ecommerce_invoices).length > 0 ?
                                            <div className='overflow-auto w-100 flex-grow-1'>
                                                <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }}>
                                                    <thead>
                                                        <tr className="fw-bold text-muted">
                                                            <th className="min-w-150px">Invoice Number</th>
                                                            <th className="min-w-150px">Items</th>
                                                            <th className="min-w-150px">{invoiceType == "Customer" ? <>Customer</> : <>User</>} Name</th>
                                                            <th className="min-w-150px">Grand Total</th>
                                                            <th className="min-w-150px">Invoice Date</th>
                                                            <th className="min-w-150px">Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            outwardSummary !== null && (invoiceType == "Customer" ? outwardSummary.customer_invoices : outwardSummary.ecommerce_invoices).map((obj) => {
                                                                return (
                                                                    <tr key={obj.customer_invoice_id}>
                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.invoice_number}</span></td>
                                                                        <td><button className='btn btn-sm btn-info rounded' onClick={() => {
                                                                            setItemsModalShow(true);
                                                                            if (invoiceType == "Ecommerce") {
                                                                                setItemsData(obj.item_list)
                                                                            }
                                                                            else {
                                                                                setItemsData(obj.item_array)
                                                                            }
                                                                        }}>View</button></td>
                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{invoiceType == "Customer" ? obj.customer_name : obj.user_name}</span></td>
                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.invoice_currency.currency_symbol}&nbsp;{formatNumber(obj.grand_total, { ...formatNumberOptions, currencyFormat: obj.invoice_currency.format, decimalPlaces: obj.invoice_currency.decimal_place })}</span></td>
                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{moment(obj.invoice_date).format("ll")}</span></td>
                                                                        <td>
                                                                            {invoiceType !== "Ecommerce" ?
                                                                                <StatusBadge status={obj.add_status} entity={"CUSTOMER_INVOICE"} />
                                                                                :
                                                                                <span className={`badge badge-light-success`} >Finalized</span>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <div className="text-muted fs-2 my-auto text-center">No data to be shown</div>
                                    }
                                </Tab>
                                <Tab eventKey="Credit Note" title="Credit Note">
                                    {
                                        outwardSummary !== null && outwardSummary.credit_notes.length > 0 ?
                                            <div className='overflow-auto w-100 flex-grow-1'>
                                                <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }}>
                                                    <thead>
                                                        <tr className="fw-bold text-muted">
                                                            <th className="min-w-150px">Credit Note Number</th>
                                                            <th className="min-w-150px">Credit Note Type</th>
                                                            <th className="min-w-150px">Items</th>
                                                            <th className="min-w-150px">Customer Name</th>
                                                            <th className="min-w-150px">Grand Total</th>
                                                            <th className="min-w-150px">Credit Note Date</th>
                                                            <th className="min-w-150px">Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            outwardSummary !== null && outwardSummary.credit_notes.map((obj) => {
                                                                return (
                                                                    <tr key={obj.credit_note_id}>
                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.credit_note_number}</span></td>
                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.credit_note_type}</span></td>
                                                                        <td><button className='btn btn-sm btn-info rounded' onClick={() => { setItemsModalShow(true); setItemsData(obj.item_array) }}>View</button></td>
                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.customer_name}</span></td>
                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.credit_note_currency.currency_symbol}&nbsp;{formatNumber(obj.grand_total, { ...formatNumberOptions, currencyFormat: obj.credit_note_currency.format, decimalPlaces: obj.credit_note_currency.decimal_place })}</span></td>
                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{moment(obj.credit_note_date).format("ll")}</span></td>
                                                                        <td><StatusBadge status={obj.add_status} entity={"CREDIT_NOTE"} /></td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <div className="text-muted fs-2 my-auto text-center">No data to be shown</div>
                                    }
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setPreviewModalShow(false) }}>Close</button>
                </Modal.Footer>
            </Modal >
        </>
    );
}
export default CreateGstSubmission;