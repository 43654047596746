import React, { useState, useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
import moment from 'moment';
import swal from 'sweetalert';
import { Link } from 'react-router-dom'
import { Modal, Tab, Tabs } from "react-bootstrap";

import { useTheme } from "./theme-provider.js"
import { getCookie } from '../common_component/cookie.js';
import PageError from "../common_component/PageError.js";
import { useAuthData } from "./auth-provider.js"
import StatusBadge from "../common_component/StatusBadge.js";

const getRecentMonth = (monthsAgo = 0) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - monthsAgo);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Ensure 2 digits
    return `${year}-${month}`;
};

function GstFilings() {
    const [state, setstate] = useState(2);
    const { theme, dropdownStyleDark } = useTheme()

    const [filingMonth, setFilingMonth] = useState(getRecentMonth())
    const [filingList, setFilingList] = useState(null);
    const [filingData, setFilingData] = useState(null);
    const [organizationGstinList, setOrganizationGstinList] = useState([]);
    const [selectedOrganizationGstin, setSelectedOrganizationGstin] = useState(null)

    const [gstinFilingSettings, setGstinFilingSettings] = useState(null)

    const [activeTab, setActiveTab] = useState("filings")

    const [userRole, setUserRole] = useState("")

    const [previewModalShow, setPreviewModalShow] = useState(false);

    const [overviewDetails, setOverviewDetails] = useState(null)
    const [overviewDetailsApiState, setOverviewDetailsApiState] = useState(false)

    const [otpModalShow, setOtpModalShow] = useState(false);
    const inputRefs = useRef([])
    const [otpInput, setOtpInput] = useState("")
    const [otpModalCallback, setOtpModalCallback] = useState(null)

    const [ptfModalShow, setPtfModalShow] = useState(false);
    const [selectedSubmissionId, setSelectedSubmissionId] = useState(null)
    const [ptfModalState, setPtfModalState] = useState(0)
    const [ptfDisplayMessage, setPtfDisplayMessage] = useState("Click on Push to save the GSTR1 submission details")
    const [pushGstFilingApiResponse, setPushGstFilingApiResponse] = useState(null)

    const [evcFileModalShow, setEvcFileModalShow] = useState(false)
    const [evcFileApiState, setEvcFileApiState] = useState(false)
    const evcInputRefs = useRef([])
    const [evcOtp, setEvcOtp] = useState("")
    const [evcOtpApiState, setEvcOtpApiState] = useState(false)
    const [wizardState, setWizardState] = useState(0)
    const [pan, setPan] = useState('')

    const [pushGstFilingApiState, setPushGstFilingApiState] = useState(false)
    const [statusGstFilingApiState, setStatusGstFilingApiState] = useState(false)
    const [ptfGstFilingApiState, setPtfGstFilingApiState] = useState(false)

    var page_name = "gst-filings";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    async function getOrganizationGstinList() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-gst-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            const default_gstin = body.data.find(x => x.default_gstin == 1);
            setOrganizationGstinList(body.data);
            setSelectedOrganizationGstin({ label: default_gstin.GstIn, value: default_gstin.unique_id, legal_name: default_gstin.legal_name, trade_name: default_gstin.trade_name })
        }
    }

    async function getGstinFilingList(filing_month, gstin) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-gstin-filing-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "filing_month": filing_month, "gstin": gstin, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            setFilingList(body.data);
        }
    }

    async function getUserInfo() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/individual-user-info`, { method: 'POST', body: JSON.stringify({ "email": user.email, "unique_id": "", "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            setUserRole(body.data[0].user_role)
        }
    }

    async function getGstinFilingSettings(gstin) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-gstin-filing-settings`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "gstin": gstin, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            if (body.data) {
                setGstinFilingSettings(JSON.parse(body.data));
            }
            else {
                setGstinFilingSettings(null)
            }
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            getOrganizationGstinList();
            getUserInfo()
        }
    }, [user, visibility, read, write])

    useEffect(() => {
        if (filingMonth != "" && selectedOrganizationGstin !== null) {
            setstate(2);
            let p1 = getGstinFilingList(filingMonth, selectedOrganizationGstin.value);
            let p2 = getGstinFilingSettings(selectedOrganizationGstin.value)

            Promise.all([p1, p2])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)
                })
        }
    }, [filingMonth, selectedOrganizationGstin])

    const cancelGstFiling = async (unique_id, msg) => {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/cancel-gstin-filing`, { method: 'POST', body: JSON.stringify({ "email": user.email, "unique_id": unique_id, "reason": msg, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation === "success") {
            getGstinFilingList(filingMonth, selectedOrganizationGstin.value);
            swal("Great!", `GST filing cancelled successfully!`, "success");
        }
        else {
            swal("Oops!", body.message, "error");
        }
    }

    const approveGstFiling = async (unique_id) => {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/approve-gstin-filing`, { method: 'POST', body: JSON.stringify({ "email": user.email, "unique_id": unique_id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation === "success") {
            getGstinFilingList(filingMonth, selectedOrganizationGstin.value);
            swal("Great!", `GST filing approved successfully!`, "success");
        }
        else {
            swal("Oops!", body.message, "error");
        }
    }

    const pushGstFiling = async (submission_id, otp) => {
        setPushGstFilingApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/push-gstin-filing`, { method: 'POST', body: JSON.stringify({ "email": user.email, ...(otp ? { "otp": otp } : {}), "submission_id": submission_id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        setPushGstFilingApiState(false)
        setPushGstFilingApiResponse(body)
        if (body.operation === "success") {
            setPtfModalState((cs) => cs + 1)
            setPtfDisplayMessage("Your data has been saved successfully. Now continue to check the status of your saved data")

            statusGstFiling(submission_id, body.info.request_id, selectedOrganizationGstin.label, selectedOrganizationGstin.value, filingMonth)
        }
        else {
            if (body.info === "RETOTPREQUEST") {
                otpModalInit((otpInput) => {
                    pushGstFiling(submission_id, otpInput);
                });
            }
            else {
                swal("Oops!", body.message, "error");
            }
        }
    }

    const statusGstFiling = async (submission_id, request_id, gstin_number, gstin_id, return_period, otp) => {
        setStatusGstFilingApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/status-gstin-filing`, { method: 'POST', body: JSON.stringify({ "email": user.email, ...(otp ? { "otp": otp } : {}), "submission_id": submission_id, "request_id": request_id, "gstin_number": gstin_number, "gstin_id": gstin_id, "return_period": return_period, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        setStatusGstFilingApiState(false)
        if (body.operation === "success") {
            if (body.info.status_cd !== "P") {
                setPtfDisplayMessage("Your submission status is Currently Processing, Click on Check Status again after a few seconds")
            }
            else {
                setPtfModalState((cs) => cs + 1)
                setPtfDisplayMessage("Finally, Proceed to Filing the GSTR submission")
            }
        }
        else {
            if (body.info === "RETOTPREQUEST") {
                otpModalInit((otpInput) => {
                    statusGstFiling(submission_id, request_id, gstin_number, gstin_id, return_period, otpInput)
                });
            }
            else {
                swal("Oops!", body.message, "error");
            }
        }
    }

    const proceedToFileGstFiling = async (submission_id, gstin_number, gstin_id, return_period, otp) => {
        setPtfGstFilingApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/proceed-to-file-gstin-filing`, { method: 'POST', body: JSON.stringify({ "email": user.email, ...(otp ? { "otp": otp } : {}), "submission_id": submission_id, "gstin_number": gstin_number, "gstin_id": gstin_id, "return_period": return_period, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        setPtfGstFilingApiState(false)
        if (body.operation === "success") {
            swal("Great!", body.message, "success");
            setPtfModalState((cs) => cs + 1)

            handlePtfModalClose()
            getGstinFilingList(filingMonth, selectedOrganizationGstin.value)
        }
        else {
            if (body.info === "RETOTPREQUEST") {
                otpModalInit((otpInput) => {
                    proceedToFileGstFiling(submission_id, gstin_number, gstin_id, return_period, otpInput)
                });
            }
            else {
                swal("Oops!", body.message, "error");
            }
        }
    }

    const getOverviewDetails = async (gstin_number, gstin_id, return_period, otp) => {
        setOverviewDetailsApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-gstr1-return-summary`, { method: 'POST', body: JSON.stringify({ "email": user.email, ...(otp ? { "otp": otp } : {}), "gstin_number": gstin_number, "gstin_id": gstin_id, "return_period": return_period, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation === 'success') {
            setOverviewDetails(body.data)
        } else {
            if (body.info === "RETOTPREQUEST") {
                otpModalInit((otpInput) => {
                    getOverviewDetails(selectedOrganizationGstin.label, selectedOrganizationGstin.value, filingMonth, otpInput);
                });
            }
            else {
                swal("Oops!", body.message, "error");
            }
        }

        setOverviewDetailsApiState(false)
    }

    const getEvcOtp = async (pan, gstin_number, gstin_id, return_period, otp) => {
        setEvcOtpApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-evc-otp`, { method: 'POST', body: JSON.stringify({ "email": user.email, ...(otp ? { "otp": otp } : {}), "pan": pan, "gstin_number": gstin_number, "gstin_id": gstin_id, "return_period": return_period, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation === 'success') {
            setWizardState(1)
        }
        else {
            if (body.info === "RETOTPREQUEST") {
                otpModalInit((otpInput) => {
                    getEvcOtp(pan, gstin_number, gstin_id, return_period, otpInput)
                });
            }
            else {
                swal("Oops!", body.message, "error");
            }
        }

        setEvcOtpApiState(false)
    }

    const evcFile = async (evcOtp, pan, gstin_number, gstin_id, return_period, otp) => {
        setEvcFileApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/gst-evc-file`, { method: 'POST', body: JSON.stringify({ "email": user.email, ...(otp ? { "otp": otp } : {}), "evcotp": evcOtp, "pan": pan, "gstin_number": gstin_number, "gstin_id": gstin_id, "return_period": return_period, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation === 'success') {
            swal("Oops!", "EVC filed successfully", "success")
        }
        else {
            if (body.info === "RETOTPREQUEST") {
                otpModalInit((otpInput) => {
                    evcFile(evcOtp, pan, gstin_number, gstin_id, return_period, otpInput)
                });
            }
            else {
                swal("Oops!", body.message, "error");
            }
        }

        setEvcFileApiState(false)
    }

    const processOtpInput = (input, i) => {
        if (input.length > 0) {
            setOtpInput(otpInput.slice(0, i) + input.slice(0, 1))
            inputRefs.current[(otpInput.length + 1) < 5 ? otpInput.length + 1 : 5].focus()
        }
        else {
            setOtpInput(otpInput.slice(0, i) + otpInput.slice(i + 1))
            if (otpInput.length - 1 == i && i !== 0) {
                inputRefs.current[i - 1].focus()
            }
        }
    }

    const processEvcOtpInput = (input, i) => {
        if (input.length > 0) {
            setEvcOtp(evcOtp.slice(0, i) + input.slice(0, 1))
            inputRefs.current[(evcOtp.length + 1) < 5 ? evcOtp.length + 1 : 5].focus()
        }
        else {
            setEvcOtp(evcOtp.slice(0, i) + evcOtp.slice(i + 1))
            if (evcOtp.length - 1 == i && i !== 0) {
                inputRefs.current[i - 1].focus()
            }
        }
    }

    const handleOtpModalClose = () => {
        setOtpModalShow(false);
        setOtpInput("");
        setOtpModalCallback(null);
    }

    const otpModalInit = (cb) => {
        setOtpModalShow(true);
        setOtpModalCallback(() => {
            return cb
        });
    }

    const handlePtfModalClose = async (confirm = false) => {
        if (confirm) {
            let ch = await swal({
                title: "Are you sure? You'll have to start over again",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })

            if (!ch) {
                return
            }
        }

        setPtfModalShow(false);

        setSelectedSubmissionId(null)
        setPtfModalState(0)
        setPtfDisplayMessage("Click on Push to save the GSTR1 submission details")
        setPushGstFilingApiResponse(null)
    }

    const handleEvcFileModalClose = () => {
        setEvcFileModalShow(false)
        setEvcOtp("")
        setPan('')
        setWizardState(0)
    }

    return (
        <>
            <div>
                <div className="d-flex flex-stack mb-5">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">GST Filings</h1>
                </div>
                <div>
                    {
                        state === 1 ?
                            <>
                                <div className="row m-2">
                                    <div className='col-md-3 p-1'>
                                        <input type="month" className='form-control' onChange={(e) => { setFilingMonth(e.target.value); setOverviewDetails(null); }} value={filingMonth} />
                                    </div>
                                    <div className='col-md-5 p-1'>
                                        <Select
                                            options={organizationGstinList.map(x => { return { label: x.GstIn, value: x.unique_id, legal_name: x.legal_name, trade_name: x.trade_name } })}
                                            onChange={(val) => { setSelectedOrganizationGstin(val); setOverviewDetails(null); }}
                                            value={selectedOrganizationGstin}
                                            placeholder="Select GSTIN..."
                                            styles={theme == 'dark' ? dropdownStyleDark : {}}
                                            components={{
                                                Option: (props) => {
                                                    return (
                                                        <components.Option  {...props}>
                                                            <span className='fw-bold'>{props.children}</span>
                                                            <div className='d-flex justify-content-between'>
                                                                <span className="fs-6">{props.data.legal_name}</span>
                                                                <span className="fs-6">{props.data.trade_name}</span>
                                                            </div>
                                                        </components.Option >
                                                    )
                                                }
                                            }}
                                        />
                                    </div>
                                    {
                                        gstinFilingSettings &&
                                        <>
                                            <div className="col-md-2 text-lg-center p-1">
                                                <button type="button" className="btn btn-lg btn-info rounded" data-kt-indicator={evcOtpApiState ? "on" : "off"} disabled={evcOtpApiState}
                                                    onClick={() => {
                                                        if (!filingList || !filingList.length || filingList.find(x => x.add_status !== 'GSTR_FILING_FILED')) {
                                                            swal("Alert!", "Please file all your entries", "warning")
                                                            return
                                                        }

                                                        setEvcFileModalShow(true)
                                                    }}
                                                >
                                                    <span className="indicator-label">EVC File</span>
                                                    <span className="indicator-progress">Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="col-md-2 text-lg-center p-1">
                                                <button type="button" className="btn btn-lg btn-info rounded"
                                                    onClick={() => {
                                                        swal({
                                                            title: "Notice!",
                                                            text: "Nil Filing feature is not currently available. Please visit the GST Portal to nil file directly.",
                                                            icon: "info",
                                                            buttons: ["Cancel", "Visit GST Portal"]
                                                        })
                                                            .then((msg) => {
                                                                if (msg) {
                                                                    window.open("https://services.gst.gov.in/services")
                                                                }
                                                            });
                                                    }}
                                                >Nil File</button>
                                            </div>
                                        </>
                                    }
                                </div>
                                {
                                    gstinFilingSettings ?
                                        <Tabs
                                            activeKey={activeTab}
                                            onSelect={(k) => setActiveTab(k)}
                                            variant="pills"
                                        >
                                            <Tab eventKey="filings" title="Filings">
                                                <div className="d-flex flex-column gap-7 gap-lg-10">
                                                    <div className="card card-flush py-4 flex-row-fluid">
                                                        <div className="card-body pt-0">
                                                            <div className='overflow-auto w-100 flex-grow-1'>
                                                                <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }}>
                                                                    <thead>
                                                                        <tr className="fw-bold text-muted">
                                                                            <th className="min-w-75px">Timestamp</th>
                                                                            <th className="min-w-75px">Filing Type</th>
                                                                            <th className="min-w-75px">Filing Data</th>
                                                                            <th className="min-w-75px">Status</th>
                                                                            <th className="min-w-50px sticky-column" style={{ backdropFilter: 'blur(10px)' }}>Actions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            filingList == null || filingList.length === 0 ?
                                                                                <tr><td className='fs-2 text-center text-gray-600' colSpan={5}>No Submissions available for this return period</td></tr>
                                                                                : filingList.map((obj, indx) => {
                                                                                    return (
                                                                                        <tr key={indx}>
                                                                                            <td><a className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{moment(obj.add_date_time).format("ll")}</a></td>
                                                                                            <td><span className="badge badge-primary">{obj.filing_type}</span></td>
                                                                                            <td><a onClick={(e) => { setFilingData(obj); setPreviewModalShow(true); }} className="btn-primary cursor-pointer">View</a></td>
                                                                                            <td><StatusBadge status={obj.add_status} entity={"GSTR_FILING"} /></td>
                                                                                            <td className="sticky-column">
                                                                                                <div className="dropdown">
                                                                                                    <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                                        <i className="fas fa-bars px-0"></i>
                                                                                                    </button>
                                                                                                    {write &&
                                                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                                            {
                                                                                                                obj.add_status !== "GSTR_FILING_FILED" &&
                                                                                                                    (
                                                                                                                        userRole === "Admin" ||
                                                                                                                        !gstinFilingSettings.Gst_Filing_Approval ||
                                                                                                                        (gstinFilingSettings.Gst_Filing_Approval && ((gstinFilingSettings.UserType === "Individual User" && gstinFilingSettings.UserValue.includes(user.email)) || (gstinFilingSettings.UserType === "Group User" && gstinFilingSettings.UserValue.includes(userRole))))
                                                                                                                    ) ?
                                                                                                                    <>
                                                                                                                        {obj.add_status === "GSTR_FILING_DRAFT" &&
                                                                                                                            <li className="py-1 dropdown-item cursor-pointer"
                                                                                                                                onClick={() => {
                                                                                                                                    swal({
                                                                                                                                        title: "Alert!",
                                                                                                                                        text: "Please type a reason before cancelling",
                                                                                                                                        icon: "warning",
                                                                                                                                        content: "input",
                                                                                                                                        buttons: true,
                                                                                                                                        dangerMode: true,
                                                                                                                                    })
                                                                                                                                        .then((msg) => {
                                                                                                                                            if (msg == "") {
                                                                                                                                                swal("Alert!", "Message cannot be empty", "warning")
                                                                                                                                            }
                                                                                                                                            else if (msg) {
                                                                                                                                                cancelGstFiling(obj.unique_id, msg)
                                                                                                                                            }
                                                                                                                                        });
                                                                                                                                }}
                                                                                                                            >Cancel</li>
                                                                                                                        }
                                                                                                                        {obj.add_status === "GSTR_FILING_DRAFT" &&
                                                                                                                            <li className="py-1 dropdown-item cursor-pointer"
                                                                                                                                onClick={() => {
                                                                                                                                    swal({
                                                                                                                                        title: "Are you sure?",
                                                                                                                                        icon: "warning",
                                                                                                                                        buttons: true,
                                                                                                                                        dangerMode: true,
                                                                                                                                    })
                                                                                                                                        .then((val) => {
                                                                                                                                            if (val) {
                                                                                                                                                approveGstFiling(obj.unique_id)
                                                                                                                                            }
                                                                                                                                        });
                                                                                                                                }}
                                                                                                                            >Approve
                                                                                                                            </li>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            obj.add_status === "GSTR_FILING_FINALIZED" &&
                                                                                                                            <li
                                                                                                                                className="py-1 dropdown-item cursor-pointer"
                                                                                                                                onClick={() => {
                                                                                                                                    pushGstFiling(obj.unique_id);
                                                                                                                                    setSelectedSubmissionId(obj.unique_id);

                                                                                                                                    setPtfModalShow(true)
                                                                                                                                }} >Proceed to file</li>
                                                                                                                        }
                                                                                                                    </>
                                                                                                                    : <li className="py-1 text-center text-gray-400">No Options</li>
                                                                                                            }
                                                                                                        </ul>
                                                                                                    }
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="overview" title="Overview">
                                                <div className="d-flex flex-column gap-2 m-5">
                                                    <div className="card card-flush py-4 flex-row-fluid">
                                                        <div className="card-body">
                                                            <div className='overflow-auto w-100 flex-grow-1'>
                                                                {
                                                                    !overviewDetails ?
                                                                        <div className="text-center">
                                                                            <button type="button" className="btn btn-lg btn-info rounded" data-kt-indicator={overviewDetailsApiState ? "on" : "off"} disabled={overviewDetailsApiState}
                                                                                onClick={() => {
                                                                                    getOverviewDetails(selectedOrganizationGstin.label, selectedOrganizationGstin.value, filingMonth);
                                                                                }}
                                                                            >
                                                                                <span className="indicator-label">Check Overview</span>
                                                                                <span className="indicator-progress">Please wait...
                                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                        :
                                                                        overviewDetails.isNil ? <div className="text-center text-gray-600 fs-3">Your Filing for this month is Nil</div> :
                                                                            <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }}>
                                                                                <thead>
                                                                                    <tr className="fw-bold text-muted">
                                                                                        <th className="min-w-100px">Description</th>
                                                                                        <th className="min-w-75px">Taxable Amount</th>
                                                                                        <th className="min-w-75px">IGST</th>
                                                                                        <th className="min-w-75px">CGST</th>
                                                                                        <th className="min-w-75px">SGST</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        overviewDetails.data.length === 0 ?
                                                                                            <tr><td className='fs-2 text-center text-gray-600' colSpan={5}>No overview available for this return period</td></tr>
                                                                                            :
                                                                                            overviewDetails.data.map((obj, indx) => {
                                                                                                return (
                                                                                                    <tr key={indx}>
                                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.sec_nm} Invoices</span></td>
                                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.ttl_tax}</span></td>
                                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.ttl_igst}</span></td>
                                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.ttl_cgst}</span></td>
                                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.ttl_sgst}</span></td>
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {
                                                        overviewDetails &&
                                                        <div className='ms-auto'>
                                                            <button type="button" className="btn btn-lg btn-info rounded" data-kt-indicator={overviewDetailsApiState ? "on" : "off"} disabled={overviewDetailsApiState}
                                                                onClick={() => {
                                                                    getOverviewDetails(selectedOrganizationGstin.label, selectedOrganizationGstin.value, filingMonth);
                                                                }}
                                                            >
                                                                <span className="indicator-label">Check Overview</span>
                                                                <span className="indicator-progress">Please wait...
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            </Tab>
                                        </Tabs>
                                        :
                                        <PageError message='Please go to GST Settings and update the Online Filing Settings for the selected GSTIN No. before proceeding.' showReloadMsg={true}>
                                            <Link className='btn btn-primary rounded' to={`/gst-settings`}>GST Settings</Link>
                                        </PageError>
                                }
                            </>
                            :
                            state === 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <PageError />
                    }
                </div>
            </div >

            <Modal show={previewModalShow} onHide={() => { setPreviewModalShow(false); }} centered>
                <Modal.Header className='py-2'>
                    <Modal.Title>View Submission Data</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setPreviewModalShow(false); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body className="py-2">
                    {
                        filingData &&
                        <Tabs
                            defaultActiveKey="invoices"
                            variant="pills"
                        >
                            <Tab eventKey="invoices" title="Invoices">
                                <div className='my-5'>
                                    <h3>Internal Invoices</h3>
                                    <div className='container'>
                                        <div className='table-responsive'>
                                            <table className='table table-striped'>
                                                <thead className='bg-light-info fs-4 fw-bold text-primary'>
                                                    <tr>
                                                        <th scope='col' style={{ paddingLeft: "10px" }}>Invoice</th>
                                                        <th scope='col'>For</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        filingData.internal_invoices.map((obj, indx) => {
                                                            return (
                                                                <tr key={indx}>
                                                                    <td className='fw-bold' style={{ paddingLeft: "10px" }}>
                                                                        <span className="d-flex align-items-center">
                                                                            <span className="bullet bg-primary me-2 rounded-circle" style={{ width: '10px', height: '10px', display: 'inline-block' }}></span>
                                                                            {obj.invoice_number}
                                                                        </span>
                                                                    </td>
                                                                    <td>{obj.invoice_for}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <h3>External Invoices</h3>
                                    {
                                        filingData.external_invoices.length ?
                                            <div className='container'>
                                                <div className='table-responsive'>
                                                    <table className='table table-striped'>
                                                        <thead className='bg-light-info fs-4 fw-bold text-primary'>
                                                            <tr>
                                                                <th scope='col'>Invoice</th>
                                                                <th scope='col'>For</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                filingData.external_invoices.map((obj, indx) => {
                                                                    return (
                                                                        <tr key={indx}>
                                                                            <td className='fw-bold'>
                                                                                <span className="bullet bg-primary me-2 rounded-circle" style={{ width: '10px', height: '10px', display: 'inline-block' }}></span>
                                                                                {obj.invoice_number}
                                                                            </td>
                                                                            <td>{obj.invoice_for}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            :
                                            <div className='fs-2 text-center text-gray-600'>No external invoices available</div>
                                    }
                                </div>
                            </Tab>
                            <Tab eventKey="filing_data" title="Filing Data">
                                <table className="table table-row-bordered">
                                    <thead>
                                        <tr className="fw-bold text-muted">
                                            <th className="min-w-50px">Description</th>
                                            <th className="min-w-25px">Taxable Amount</th>
                                            <th className="min-w-25px">IGST</th>
                                            <th className="min-w-25px">CGST</th>
                                            <th className="min-w-25px">SGST</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <RenderFilingDataTabBody
                                            label="B2B Invoices"
                                            data={filingData.filing_data.b2b}
                                            calcFunc={(data) => {
                                                let fo = { ttl_taxable: 0, ttl_igst: 0, ttl_cgst: 0, ttl_sgst: 0 }

                                                for (const ctinObj of data) {
                                                    for (const inv of ctinObj.inv) {
                                                        for (const itm of inv.itms) {
                                                            fo.ttl_taxable += itm.itm_det?.txval || 0
                                                            fo.ttl_igst += itm.itm_det?.iamt || 0
                                                            fo.ttl_cgst += itm.itm_det?.camt || 0
                                                            fo.ttl_sgst += itm.itm_det?.samt || 0
                                                        }
                                                    }
                                                }

                                                return fo
                                            }}
                                        />
                                        <RenderFilingDataTabBody
                                            label="B2CS Invoices"
                                            data={filingData.filing_data.b2cs}
                                            calcFunc={(data) => {
                                                let fo = { ttl_taxable: 0, ttl_igst: 0, ttl_cgst: 0, ttl_sgst: 0 }

                                                for (const item of data) {
                                                    fo.ttl_taxable += item?.txval || 0
                                                    fo.ttl_igst += item?.iamt || 0
                                                    fo.ttl_cgst += item?.camt || 0
                                                    fo.ttl_sgst += item?.samt || 0
                                                }

                                                return fo
                                            }}
                                        />
                                        <RenderFilingDataTabBody
                                            label="B2CL Invoices"
                                            data={filingData.filing_data.b2cl}
                                            calcFunc={(data) => {
                                                let fo = { ttl_taxable: 0, ttl_igst: 0, ttl_cgst: 0, ttl_sgst: 0 }

                                                for (const posObj of data) {
                                                    for (const inv of posObj.inv) {
                                                        for (const itm of inv.itms) {
                                                            fo.ttl_taxable += itm.itm_det?.txval || 0
                                                            fo.ttl_igst += itm.itm_det?.iamt || 0
                                                            fo.ttl_cgst += itm.itm_det?.camt || 0
                                                            fo.ttl_sgst += itm.itm_det?.samt || 0
                                                        }
                                                    }
                                                }

                                                return fo
                                            }}
                                        />
                                    </tbody>
                                </table>
                            </Tab>
                        </Tabs>
                    }
                </Modal.Body>
            </Modal>

            <Modal show={otpModalShow} onHide={() => { handleOtpModalClose() }} centered>
                <Modal.Body>
                    <div className='container text-center'>
                        <div className='my-5'>
                            <i className="bi bi-patch-check-fill text-primary" style={{ fontSize: "100px" }}></i>
                        </div>
                        <span className='fs-1 my-5'>Enter an OTP</span>
                        <div className='h-otp-input'>
                            {
                                Array.from({ length: 6 }, (_, i) => (i * 1) + 0).map((x) => {
                                    return <input
                                        key={x}
                                        ref={(el) => inputRefs.current[x] = el}
                                        type='number'
                                        value={otpInput.slice(x, x + 1)}
                                        onChange={(e) => { processOtpInput(e.target.value, x) }}
                                    />
                                })
                            }
                        </div>
                        <button className='btn btn-primary mt-10' onClick={() => {
                            if (otpInput.length < 6) {
                                swal("Oops!", "Invalid OTP", "warning")
                                return
                            }

                            otpModalCallback(otpInput)
                            handleOtpModalClose()
                        }}>Submit</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={ptfModalShow} onHide={() => { handlePtfModalClose(true) }} size='lg' centered>
                <Modal.Header className='py-2'>
                    <Modal.Title>GSTR1 Filing</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handlePtfModalClose(true) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className='border border-dashed border-primary fs-1 mb-4 p-2 rounded d-flex justify-content-center align-items-center' style={{ height: "100px" }}>
                            {
                                pushGstFilingApiState || statusGstFilingApiState || ptfGstFilingApiState ?
                                    <div className='text-center'><div className="spinner-border text-primary" style={{ width: "60px", height: "60px" }}></div></div> :
                                    <div>{ptfDisplayMessage}</div>
                            }
                        </div>
                        <div className='d-flex flex-wrap gap-5 justify-content-between align-items-center'>
                            <div className={`${ptfModalState == 0 ? "border-primary" : "border-secondary"} p-3 rounded text-center`} style={{ width: "175px", height: "175px", borderStyle: "double", borderWidth: "thick" }} >
                                <div><i className="bi bi-box-arrow-in-down display-1 rounded text-primary"></i></div>
                                {
                                    write &&
                                    <button type="button" className="btn btn-primary mt-7" data-kt-indicator={pushGstFilingApiState ? "on" : "off"} disabled={pushGstFilingApiState || ptfModalState != 0} onClick={() => { pushGstFiling(selectedSubmissionId); }}>
                                        <span className="indicator-label">Push To Save</span>
                                        <span className="indicator-progress">Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                }
                            </div>
                            <div><i className="bi bi-arrow-right-circle-fill display-5 text-black"></i></div>
                            <div className={`${ptfModalState == 1 ? "border-primary" : "border-secondary"} p-3 rounded text-center`} style={{ width: "175px", height: "175px", borderStyle: "double", borderWidth: "thick" }} >
                                <div><i className="bi bi-clipboard2-data display-1 rounded text-primary"></i></div>
                                {
                                    write &&
                                    <button type="button" className="btn btn-primary mt-7" data-kt-indicator={statusGstFilingApiState ? "on" : "off"} disabled={statusGstFilingApiState || ptfModalState != 1} onClick={() => { statusGstFiling(selectedSubmissionId, pushGstFilingApiResponse.info.request_id, selectedOrganizationGstin.label, selectedOrganizationGstin.value, filingMonth) }}>
                                        <span className="indicator-label">Check Status</span>
                                        <span className="indicator-progress">Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                }
                            </div>
                            <div><i className="bi bi-arrow-right-circle-fill display-5 text-black"></i></div>
                            <div className={`${ptfModalState == 2 ? "border-primary" : "border-secondary"} p-3 rounded text-center`} style={{ width: "175px", height: "175px", borderStyle: "double", borderWidth: "thick" }} >
                                <div><i className="bi bi-clipboard2-check-fill display-1 rounded text-primary"></i></div>
                                {
                                    write &&
                                    <button type="button" className="btn btn-primary mt-7" data-kt-indicator={ptfGstFilingApiState ? "on" : "off"} disabled={ptfGstFilingApiState || ptfModalState != 2}
                                        onClick={() => {
                                            swal({
                                                title: "Final Confirmation",
                                                text: "This will submit the saved details of this submission, and CANNOT be further changed",
                                                icon: "warning",
                                                buttons: true,
                                                dangerMode: true,
                                            })
                                                .then((val) => {
                                                    if (val) {
                                                        proceedToFileGstFiling(selectedSubmissionId, selectedOrganizationGstin.label, selectedOrganizationGstin.value, filingMonth)
                                                    }
                                                });
                                        }}
                                    >
                                        <span className="indicator-label">Proceed To File</span>
                                        <span className="indicator-progress">Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={evcFileModalShow} onHide={() => { handleEvcFileModalClose(); }} size="md" centered>
                <Modal.Header>
                    <Modal.Title>{`${wizardState === 0 ? "Enter Pan number associated with the GSTIN" : "EVC OTP sent successfully"}`}</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleEvcFileModalClose(); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {
                            wizardState === 0 ?
                                <div className="row gy-5 justify-content-center px-20">
                                    <input type="text" className="form-control form-control-solid" value={pan} onChange={(e) => { setPan(e.target.value) }} placeholder='Enter PAN number' />
                                </div>
                                :
                                <div className='container text-center'>
                                    <div className='my-5'>
                                        <i className="bi bi-patch-check-fill text-primary" style={{ fontSize: "100px" }}></i>
                                    </div>
                                    <span className='fs-1 my-5'>Enter EVC OTP</span>
                                    <div className='h-otp-input'>
                                        {
                                            Array.from({ length: 6 }, (_, i) => (i * 1) + 0).map((x) => {
                                                return <input
                                                    key={x}
                                                    ref={(el) => evcInputRefs.current[x] = el}
                                                    type='number'
                                                    value={evcOtp.slice(x, x + 1)}
                                                    onChange={(e) => { processEvcOtpInput(e.target.value, x) }}
                                                />
                                            })
                                        }
                                    </div>
                                </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        wizardState === 0 ?
                            <button className="btn btn-primary rounded" disabled={!pan}
                                onClick={() => {
                                    if (!(pan && pan.match(/[A-Z]{5}[0-9]{4}[A-Z]{1}$/))) {
                                        swal("Oops!", "Invalid PAN", "warning")
                                        return
                                    }

                                    getEvcOtp(pan, selectedOrganizationGstin.label, selectedOrganizationGstin.value, filingMonth);
                                }}
                            >Get EVC OTP</button>
                            :
                            <>
                                <button className="btn btn-primary rounded" disabled={!pan}
                                    onClick={() => {
                                        if (!(pan && pan.match(/[A-Z]{5}[0-9]{4}[A-Z]{1}$/))) {
                                            swal("Oops!", "Invalid PAN", "warning")
                                            return
                                        }

                                        getEvcOtp(pan, selectedOrganizationGstin.label, selectedOrganizationGstin.value, filingMonth);
                                    }}
                                >Resend OTP</button>
                                <button className="btn btn-success rounded" data-kt-indicator={evcOtpApiState || evcFileApiState ? "on" : "off"} disabled={evcOtpApiState || evcFileApiState}
                                    onClick={() => {
                                        if (evcOtp.length < 6) {
                                            swal("Oops!", "Invalid OTP", "warning")
                                            return
                                        }

                                        swal({
                                            title: "Are you sure to file EVC?",
                                            text: "This will submit the filing data for this month and cannot be changed further.",
                                            icon: "warning",
                                            buttons: true,
                                            dangerMode: true,
                                        }).then((value) => {
                                            if (!value) {
                                                return
                                            }

                                            evcFile(evcOtp, pan, selectedOrganizationGstin.label, selectedOrganizationGstin.value, filingMonth)
                                        })
                                    }}
                                >
                                    <span className="indicator-label">EVC File</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </>
                    }
                </Modal.Footer>
            </Modal >
        </>
    );
}

const RenderFilingDataTabBody = ({ label, data, calcFunc }) => {

    let { ttl_taxable, ttl_igst, ttl_cgst, ttl_sgst } = calcFunc(data)

    return <tr className="text-dark fw-bold text-hover-primary">
        <td><span className="fs-6">{label}</span></td>
        <td><span className="fs-6">{ttl_taxable}</span></td>
        <td><span className="fs-6">{ttl_igst}</span></td>
        <td><span className="fs-6">{ttl_cgst}</span></td>
        <td><span className="fs-6">{ttl_sgst}</span></td>
    </tr>
}

export default GstFilings;