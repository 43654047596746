import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from '@sweetalert/with-react'
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTheme } from "./theme-provider.js"
import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import PageError from '../common_component/PageError.js';


export const EditSalesReturn = (props) => {

    const [salesReturnDetails, setSalesReturnDetails] = useState(null)
    const [customerDeliveryChallanList, setCustomerDeliveryChallanList] = useState([])
    // const [relatedCustomerSalesReturnList, setRelatedCustomerSalesReturnList] = useState([])

    const [branchList, setBranchlist] = useState([])
    const [warehouseList, setWarehouseList] = useState([])
    const [transactionSeriesList, setTransactionSeriesList] = useState([])
    const [salesReturnNumber, setSalesReturnNumber] = useState("")

    const [selectedCustomerInvoice, setSelectedCustomerInvoice] = useState(null)

    const [relatedSalesReturns, setRelatedSalesReturns] = useState([]) // for validation 
    const [mergedChallanItemList, setMergedChallanItemList] = useState([])

    const [salesReturnItemArray, setSalesReturnItemArray] = useState([{ item_id: null, item_name: null, item_stock_type: null, challan_info: null, delivered_quantity: 0 }])

    const [submitButtonState, setSubmitButtonState] = useState(false)

    const { theme, dropdownStyleDark } = useTheme()

    const history = useHistory()

    const [selectedItemRef, setSelectedItemRef] = useState(null)
    const [configureItemModalShow, setConfigureItemModalShow] = useState(false)

    const [approvalInfo, setApprovalInfo] = useState(null);

    const [state, setstate] = useState(2);
    const [failedPageMessage, setFailedPageMessage] = useState("Something went wrong. Please reload this page.")

    var page_name = "create-sales-return";
    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    async function getSalesReturnDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-sales-return-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "sales_return_id": props.match.params.id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation === "success") {
            if (["SALES_RETURN_DRAFT", "SALES_RETURN_PENDING_APPROVAL", "SALES_RETURN_FINALIZED"].includes(body.data.add_status)) {
                setSalesReturnDetails(body.data)
                return body.data
            }
            else {
                throw (<span>{body.data.credit_note_number} is not allowed to edit</span>)
            }
        }
        else {
            throw "No data is available"
        }
    }

    async function getCustomerDeliveryChallanList(customer_invoice_id) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-customer-challan-by-invoice-id`, { method: 'POST', body: JSON.stringify({ "email": user.email, "customer_invoice_id": customer_invoice_id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation === "success") {
            setCustomerDeliveryChallanList(body.data);
        }
    }

    async function getRelatedCustomerSalesReturnList(customer_invoice_id) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-sales-return-list-by-invoice`, { method: 'POST', body: JSON.stringify({ "email": user.email, "customer_invoice_id": customer_invoice_id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation === "success") {
            // setRelatedCustomerSalesReturnList(body.data);
            mergeReturnedItems(body.data)
        }
    }

    async function getApprovalDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-specific-approval`, {
            method: 'POST', body: JSON.stringify({ "organization_id": organization_id, "key": "goods_receive_approval", "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
        });
        const body = await response.json();
        if (body.operation === "success") {
            setApprovalInfo(body.data);
        }
    }

    async function getBranchDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-branches`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation == "success") {
            setBranchlist(body.data);
        }
        else {
            throw "branch list api error"
        }
    }

    async function getWarehouses() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/list-warehouse`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            setWarehouseList(body.data)
        }
    }

    async function getTransactionSeriesList() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-transaction-series-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            setTransactionSeriesList(body.data)
        }
        else {
            throw "transaction series api list error"
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            getSalesReturnDetails()
                .then((val) => {
                    let p1 = getCustomerDeliveryChallanList(val.customer_invoice_id)
                    let p2 = getRelatedCustomerSalesReturnList(val.customer_invoice_id)
                    let p3 = getApprovalDetails()
                    let p4 = getBranchDetails()
                    let p5 = getWarehouses()
                    let p6 = getTransactionSeriesList()

                    Promise.all([p1, p2, p3, p4, p5, p6])
                        .then(() => {
                            setstate(1)
                            console.log("all apis done")
                        })
                        .catch((err) => {
                            setstate(3);
                            setFailedPageMessage(err)
                            console.log(err)
                        })
                })
                .catch((err) => {
                    setstate(3);
                    setFailedPageMessage(err)
                    console.log(err)
                })

        }
    }, [user, visibility, read, write])

    useEffect(() => {
        if ((salesReturnDetails !== null) && (branchList.length > 0) && (transactionSeriesList.length > 0) && (customerDeliveryChallanList.length > 0)) {

            setSelectedCustomerInvoice({ label: salesReturnDetails.customer_invoice_number, value: salesReturnDetails.customer_invoice_id })

            let challan_merged_data = []
            for (const ch of customerDeliveryChallanList) {
                ch.item_array.forEach(item => {

                    let t = challan_merged_data.find(x => (x.item_id === item.item_id))
                    if (t) {
                        t.challan_info.push({ dc_id: ch.delivery_challan_id, dc_number: ch.delivery_challan_number, unit: item.quantity, item_data: item.item_data })
                        t.delivered_quantity += item.quantity
                    }
                    else {

                        let tobj = {
                            item_id: item.item_id,
                            item_name: item.item_name,
                            item_stock_type: item.item_stock_type,
                            challan_info: [{ dc_id: ch.delivery_challan_id, dc_number: ch.delivery_challan_number, unit: item.quantity, item_data: item.item_data }],
                            delivered_quantity: item.quantity
                        }

                        challan_merged_data.push(tobj)
                    }
                })
            }
            setMergedChallanItemList(challan_merged_data)

            let t = salesReturnDetails.item_array.map((item) => {
                let tItem = challan_merged_data.find(x => x.item_id === item.item_id)

                item.delivered_quantity = tItem.delivered_quantity
                item.challan_info = tItem.challan_info.map(tCh => {
                    let ch = item.challan_info.find(x => x.dc_id === tCh.dc_id)

                    tCh.returned_quantity = ch?.returned_quantity || 0
                    tCh.returned_item_data = ch?.returned_item_data || null
                    tCh.challan_expanded = false

                    return tCh
                })
                return item
            })
            setSalesReturnItemArray(t)

        }
    }, [salesReturnDetails, branchList, transactionSeriesList, customerDeliveryChallanList])

    const mergeReturnedItems = (salesReturns) => {
        const mergedItems = [];

        salesReturns.forEach(salesReturn => {
            salesReturn.item_array.forEach(item => {
                let existingItem = mergedItems.find(mergedItem => mergedItem.item_id === item.item_id);

                if (existingItem) {
                    item.challan_info.forEach(challan => {
                        let existingChallan = existingItem.challan_info.find(mergedChallan => mergedChallan.dc_id === challan.dc_id);

                        if (existingChallan) {
                            existingChallan.returned_quantity += challan.returned_quantity;

                            if (challan.returned_item_data) {
                                if (!existingChallan.returned_item_data) {
                                    existingChallan.returned_item_data = []
                                }

                                if (item.item_stock_type === "Track Serial Number") {
                                    existingChallan.returned_item_data = [...existingChallan.returned_item_data, ...challan.returned_item_data]
                                }
                                else if (item.item_stock_type === "Track Batches") {
                                    challan.returned_item_data.forEach(batch => {
                                        let existingBatch = existingChallan.returned_item_data.find(mergedBatch => mergedBatch.uid === batch.uid);
                                        if (existingBatch) {
                                            existingBatch.unit += batch.unit
                                        }
                                        else {
                                            existingChallan.returned_item_data.push({ ...batch })
                                        }
                                    })
                                }
                            }
                        }
                        else {
                            existingItem.challan_info.push({ ...challan })
                        }
                    })
                }
                else {
                    mergedItems.push({ ...item })
                }
            });
        });
        setRelatedSalesReturns(mergedItems)
    }



    const validateSalesReturn = () => {

        if (salesReturnItemArray.find(x => x.item_id == null) || salesReturnItemArray.length === 0) {
            swal("Oops!", "Choose a product before submitting", "warning")
            return false
        }

        let t = salesReturnItemArray.find(o => {
            if (o.item_name === null) {
                return o
            }
        })
        if (t != undefined) {
            swal("Oops!", "Please choose an item before proceeding", "warning");
            return false
        }

        let t2 = salesReturnItemArray.find(item => {
            return item.challan_info.reduce((p, o) => p + o.returned_quantity, 0) === 0
        })
        if (t2) {
            swal("Oops!", "Configure all items before proceeding", "warning");
            return false
        }

        return true
    }

    const updateSalesReturn = (val) => {
        var object = {}

        object.sales_return_id = props.match.params.id
        object.sales_return_number = salesReturnNumber
        object.customer_invoice_number = selectedCustomerInvoice.label

        let t = salesReturnItemArray.map(item => {
            return {
                item_id: item.item_id,
                item_name: item.item_name,
                item_stock_type: item.item_stock_type,
                challan_info: item.challan_info.filter(x => x.returned_quantity > 0).map(x => {
                    let t3 = { ...x }
                    delete t3.challan_expanded;
                    delete t3.item_data;
                    delete t3.unit;

                    return t3
                })
            }
        })

        object.item_array = t

        object.save_flag = val
        object.organization_id = organization_id
        object.page_name = page_name;

        // console.log(object);
        setSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-sales-return-details`, object, headers).then((res) => {
            setSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", `Details of sales return ${res.data.data.sales_return_number} updated for Invoice: ${res.data.data.customer_invoice_number} successfully!`, "success").then(() => {
                    history.push(`/view-sales-return-details/${res.data.data.sales_return_id}`)
                });
            }
            else if (res.data.operation === "danger" && res.data.ref === "incorrect_id") {
                swal("Oops!", res.data.message, "error")
            }
            else {
                swal("Oops!", res.data.message, "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const handleConfigureItemModalClose = () => {
        setSelectedItemRef(null)
        setConfigureItemModalShow(false);

    }

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                            <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Edit Sales Invoice Return
                                <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                            </h1>
                        </div>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        <div className="card">
                            <div className="card-body">
                                {
                                    state == 1 ?
                                        <>
                                            <div className="row gx-0 ">
                                                <div className="col-md-6 px-1 mb-4">
                                                    <label className="required fs-5 fw-bold mb-2">Invoice Number</label>
                                                    <Select
                                                        value={selectedCustomerInvoice}
                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                        isDisabled
                                                    />
                                                </div>
                                                <div className="col-md-3 px-1 mb-2">
                                                    <label className="required fs-5 fw-bold mb-2">Customer Name:</label>
                                                    <Select
                                                        value={{ label: salesReturnDetails.customer_name, value: salesReturnDetails.customer_id }}
                                                        isDisabled
                                                    />
                                                </div>
                                                <div className="col-md-3 px-1 mb-2">
                                                    <label className="fs-5 fw-bold mb-2">Return Number</label>
                                                    <input type="text" className="form-control form-control-solid" value={salesReturnDetails.sales_return_number} readOnly disabled />
                                                </div>
                                            </div>
                                            <div className="row gx-0 mt-3">
                                                <div className="col-md-4 px-1 mb-2">
                                                    <label className="fs-5 fw-bold mb-2 me-2">Transaction Series:</label>
                                                    <span className="d-inline-block p-2 text-primary bg-light rounded">
                                                        {transactionSeriesList.find(x => x.transaction_series_id === salesReturnDetails.transaction_series_id).series_name}
                                                    </span>
                                                </div>
                                                <div className="col-md-4 px-1 mb-2">
                                                    <label className="fs-5 fw-bold mb-2 me-2">Branch:</label>
                                                    <span className="d-inline-block p-2 text-primary bg-light rounded">
                                                        {branchList.find(x => x.branch_id === salesReturnDetails.branch_id).branch_name}
                                                    </span>
                                                </div>
                                                <div className="col-md-4 px-1 mb-2">
                                                    <label className="fs-5 fw-bold mb-2 me-2">Warehouse:</label>
                                                    <span className="d-inline-block p-2 text-primary bg-light rounded">
                                                        {warehouseList.find(x => x.wid === salesReturnDetails.warehouse_id).name}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className='container mt-15'>
                                                {
                                                    (selectedCustomerInvoice !== null && mergedChallanItemList.length > 0) ?
                                                        <div className=''>
                                                            <label className="fs-5 fw-bold mb-10">Choose Items</label>

                                                            <div className='' style={{ overflowX: "auto" }}>
                                                                <div className='container px-0' style={{ width: "80rem" }}>

                                                                    <div className="row mb-5 gx-0">
                                                                        <div className="col-4"><p className="fs-5 text-center">Product Name</p></div>
                                                                        <div className="col-2"><p className="fs-5 text-center">Delivered Qty</p></div>
                                                                        <div className="col-2"><p className="fs-5 text-center">Return Qty</p></div>
                                                                        <div className="col-2"><p className="fs-5 text-center">Configure</p></div>
                                                                        <div className="col-2"><p className="fs-5 text-center"></p></div>
                                                                    </div>
                                                                    {
                                                                        salesReturnItemArray.map((obj, indx) => {
                                                                            return (
                                                                                <div key={indx} className="row py-2 align-items-center">
                                                                                    <div className="col-4 px-3">
                                                                                        <Select
                                                                                            options={mergedChallanItemList.map(x => { return { label: x.item_name, value: x.item_id } })}
                                                                                            onChange={(val) => {
                                                                                                if (salesReturnItemArray.find(x => x.item_id === val.value)) {
                                                                                                    swal("Oops!", "Duplicate Items cannot be added", "warning")
                                                                                                    return
                                                                                                }
                                                                                                let pd = mergedChallanItemList.find(x => x.item_id === val.value);

                                                                                                let temp = salesReturnItemArray.map(a => { return { ...a } })
                                                                                                temp[indx].item_id = val.value
                                                                                                temp[indx].item_name = val.label
                                                                                                temp[indx].item_stock_type = pd.item_stock_type
                                                                                                temp[indx].challan_info = pd.challan_info.map((x) => { return { ...x, returned_quantity: 0, returned_item_data: null, challan_expanded: false } })
                                                                                                temp[indx].delivered_quantity = pd.delivered_quantity
                                                                                                setSalesReturnItemArray(temp)
                                                                                            }}
                                                                                            value={obj.item_id == null ? null : { label: obj.item_name, value: obj.item_id }}
                                                                                            styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                                            placeholder="Select Product..."
                                                                                            menuPosition={'fixed'}
                                                                                            menuShouldBlockScroll={true}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-2 px-3 text-center">
                                                                                        <input type="number" className="form-control form-control-solid px-2" value={obj.delivered_quantity} readOnly />
                                                                                    </div>

                                                                                    <div className="col-2 px-3 text-center">
                                                                                        <input type="number" className="form-control form-control-solid px-2" value={obj.challan_info === null ? 0 : obj.challan_info.reduce((p, o) => { return p + o.returned_quantity }, 0)} readOnly />
                                                                                    </div>

                                                                                    <div className="col-2 px-3 text-center">
                                                                                        {
                                                                                            obj.item_stock_type === "None" ?
                                                                                                <>
                                                                                                    <button className='btn btn-light-success px-4' onClick={() => { setConfigureItemModalShow(true); setSelectedItemRef(indx); }} >
                                                                                                        Quantity
                                                                                                    </button>
                                                                                                </>
                                                                                                :
                                                                                                obj.item_stock_type === "Track Serial Number" ?
                                                                                                    <>
                                                                                                        <button className='btn btn-light-success px-4' onClick={() => { setConfigureItemModalShow(true); setSelectedItemRef(indx); }} >
                                                                                                            Serial
                                                                                                        </button>
                                                                                                    </>
                                                                                                    : obj.item_stock_type === "Track Batches" ?
                                                                                                        <>
                                                                                                            <button className='btn btn-light-success px-4' onClick={() => { setConfigureItemModalShow(true); setSelectedItemRef(indx); }} >
                                                                                                                Batch
                                                                                                            </button>
                                                                                                        </>
                                                                                                        : <div className='text-gray-600 fs-4 text-center'>No data required for this item</div>
                                                                                        }
                                                                                    </div>

                                                                                    <div className="col-1 px-1 text-center">
                                                                                        <button className='btn btn-link btn-sm text-hover-danger fs-4' style={{ flex: "1" }} onClick={() => {
                                                                                            let temp = salesReturnItemArray.map(a => { return { ...a } })
                                                                                            temp.splice(indx, 1)
                                                                                            setSalesReturnItemArray(temp)
                                                                                        }}>✖</button>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                    {
                                                                        salesReturnItemArray.length < mergedChallanItemList.length &&
                                                                        (
                                                                            <button className="btn btn-light-primary btn-sm p-2" onClick={() => { setSalesReturnItemArray([...salesReturnItemArray, { item_id: null, item_name: null, item_stock_type: null, challan_info: null, delivered_quantity: 0 }]) }}> <i className="bi bi-plus-circle"></i>Add</button>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                        :
                                                        <div className="flex-lg-row-fluid">
                                                            <div className="card card-flush my-5 shadow-sm border-0">
                                                                <div className="card-body p-5">
                                                                    <div className="bg-light-secondary border p-13 rounded-3 position-relative">
                                                                        <div className="d-flex flex-wrap flex-stack flex-grow-1 align-items-center gap-3">
                                                                            <div className="fw-bold">
                                                                                <h4 className="text-gray-800 fw-bolder mb-3">
                                                                                    <i className="bi bi-file-earmark-excel-fill display-6 me-3 text-danger"></i>
                                                                                    No Challan Created for This Invoice
                                                                                </h4>
                                                                                <div className="fs-6 text-gray-600">
                                                                                    There are no challans associated with this invoice. Please create a challan to proceed.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                }
                                            </div>

                                            <div className="d-flex justify-content-end mt-15 mx-3">
                                                {
                                                    write == 1 &&
                                                    <button type="button" className="btn btn-lg btn-light-primary mt-15 mx-3 rounded" data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState} onClick={() => {
                                                        validateSalesReturn() && updateSalesReturn(false)
                                                    }}>
                                                        <span className="indicator-label">Save as Draft</span>
                                                        <span className="indicator-progress">Please wait...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    </button>
                                                }
                                                {
                                                    write == 1 &&
                                                    <button type="button" className="btn btn-lg btn-light-info mt-15 mx-3 rounded" data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState} onClick={() => {
                                                        validateSalesReturn() && updateSalesReturn(true)
                                                    }}>
                                                        <span className="indicator-label">Update</span>
                                                        <span className="indicator-progress">Please wait...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    </button>
                                                }
                                            </div>

                                        </>
                                        : state == 2 ?
                                            <div className="">
                                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                            </div>
                                            :
                                            <PageError message={failedPageMessage}>
                                                <Link to={`/sales-return-list`} className="btn btn-sm btn-primary me-3 rounded cursor-pointer">Go to Sales Return List</Link>
                                            </PageError>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div >

            <Modal show={configureItemModalShow} onHide={() => { handleConfigureItemModalClose() }} dialogClassName="h-modal-90" centered>
                <Modal.Header>
                    <Modal.Title>
                        <span className='fs-5 text-gray-500'>
                            Configure
                        </span>
                        <span className="fs-2 ps-2 text-primary">
                            {selectedItemRef !== null && salesReturnItemArray[selectedItemRef].item_name}
                        </span>
                        <span className="fs-2 ps-2 text-gray-500">
                            Total Quantity: {selectedItemRef !== null && salesReturnItemArray[selectedItemRef].delivered_quantity}
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-gray-200'>
                    <div className=''>
                        <div className='row gx-3'>
                            {
                                selectedItemRef !== null && salesReturnItemArray[selectedItemRef].challan_info.map((obj, i) => {
                                    return (
                                        <div className="col-md-4 my-3 px-2" key={i}>
                                            <div className="card shadow-sm border-0" style={{ borderRadius: '10px', transition: 'transform 0.3s' }}
                                                onMouseEnter={(e) => e.currentTarget.style.transform = 'translateY(-5px)'}
                                                onMouseLeave={(e) => e.currentTarget.style.transform = 'translateY(0)'}
                                            >
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                                        <div className="btn btn-primary">
                                                            {obj.dc_number} <span className="badge badge-light">{obj.unit}</span>
                                                        </div>
                                                        <button className="btn btn-sm" style={{ backgroundColor: "rgb(102 161 224)", color: 'white', border: 'none', padding: '0.25rem 0.5rem', borderRadius: '5px', transition: 'background-color 0.3s' }}
                                                            onClick={() => {
                                                                let t = salesReturnItemArray.map((x) => ({ ...x }))
                                                                t[selectedItemRef].challan_info[i].challan_expanded = !t[selectedItemRef].challan_info[i].challan_expanded
                                                                setSalesReturnItemArray(t)
                                                            }}
                                                        >
                                                            {
                                                                obj.challan_expanded ?
                                                                    <svg height="28" viewBox="0 -960 960 960" width="28">
                                                                        <path d="m283-345-43-43 240-240 240 239-43 43-197-197-197 198Z" />
                                                                    </svg> :
                                                                    <svg height="28" viewBox="0 -960 960 960" width="28">
                                                                        <path d="M480-345 240-585l43-43 197 198 197-197 43 43-240 239Z" />
                                                                    </svg>
                                                            }
                                                        </button>
                                                    </div>

                                                    {
                                                        selectedItemRef !== null && salesReturnItemArray[selectedItemRef].item_stock_type === "Track Serial Number" ?
                                                            obj.challan_expanded &&
                                                            <div className="mb-3">
                                                                <div className="fw-bold fs-6 text-primary mb-2">Associated Serials</div>
                                                                <div className="d-flex flex-wrap gap-2">
                                                                    {
                                                                        obj.item_data.map((serial) => {

                                                                            let item = relatedSalesReturns.find(x => x.item_id === salesReturnItemArray[selectedItemRef].item_id)
                                                                            let challan = item?.challan_info.find(x => x.dc_id === obj.dc_id)
                                                                            let s = challan?.returned_item_data.find(x => x.uid === serial.uid)

                                                                            return (
                                                                                <div
                                                                                    key={serial.uid}
                                                                                    className={`badge ${s ? "badge-danger" : obj.returned_item_data !== null && obj.returned_item_data.find(z => z.uid === serial.uid) && "badge-primary rounded-pill"} text-dark shadow-sm fs-2 h-hover-scale cursor-pointer`}
                                                                                    style={{ padding: '0.5rem', borderRadius: '5px' }}
                                                                                    onClick={() => {
                                                                                        if (s) {
                                                                                            swal("Cannot be Selected!", "Sales Return has already been created for this serial", "warning")
                                                                                            return
                                                                                        }

                                                                                        let t = salesReturnItemArray.map((x) => ({ ...x }))
                                                                                        let arr = t[selectedItemRef].challan_info[i].returned_item_data

                                                                                        if (arr === null) {
                                                                                            t[selectedItemRef].challan_info[i].returned_item_data = [serial]
                                                                                            t[selectedItemRef].challan_info[i].returned_quantity = 1
                                                                                        } else {
                                                                                            let fi = arr.findIndex(x => x.uid === serial.uid)
                                                                                            if (fi !== -1) {
                                                                                                if (arr.length === 1) {
                                                                                                    t[selectedItemRef].challan_info[i].returned_item_data = null
                                                                                                    t[selectedItemRef].challan_info[i].returned_quantity = 0
                                                                                                } else {
                                                                                                    t[selectedItemRef].challan_info[i].returned_item_data.splice(fi, 1)
                                                                                                    t[selectedItemRef].challan_info[i].returned_quantity -= 1
                                                                                                }
                                                                                            }
                                                                                            else {
                                                                                                t[selectedItemRef].challan_info[i].returned_item_data.push(serial)
                                                                                                t[selectedItemRef].challan_info[i].returned_quantity += 1
                                                                                            }
                                                                                        }
                                                                                        setSalesReturnItemArray(t)
                                                                                    }}
                                                                                >
                                                                                    {serial.serial_name}
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            selectedItemRef !== null && salesReturnItemArray[selectedItemRef].item_stock_type === "Track Batches" ?
                                                                obj.challan_expanded &&
                                                                <div className="mb-3">
                                                                    <div className="fw-bold fs-6 text-primary mb-2">Associated Batches</div>
                                                                    <div className="d-flex flex-wrap gap-2 justify-content-center">
                                                                        {
                                                                            obj.item_data.map((batch) => {

                                                                                let item = relatedSalesReturns.find(x => x.item_id === salesReturnItemArray[selectedItemRef].item_id)
                                                                                let challan = item?.challan_info.find(x => x.dc_id === obj.dc_id)
                                                                                let b = challan?.returned_item_data.find(x => x.uid === batch.uid)

                                                                                return (
                                                                                    <div key={batch.uid} className="border border-2 border-primary fs-2 p-1 rounded text-center">
                                                                                        <span className="badge fs-1 me-2" style={{ backgroundColor: "#2e8ee48a" }}>
                                                                                            {batch.batch_reference + " - " + batch.unit}
                                                                                        </span>
                                                                                        {
                                                                                            b && <span className="badge badge-danger rounded-pill fs-1">{b.unit}</span>
                                                                                        }
                                                                                        <div class="d-flex align-items-center gap-3">
                                                                                            <button className="btn btn-outline-danger btn-sm rounded-circle fs-1 px-5 py-1"
                                                                                                onClick={() => {
                                                                                                    let t = salesReturnItemArray.map((x) => ({ ...x }))
                                                                                                    let arr = t[selectedItemRef].challan_info[i].returned_item_data

                                                                                                    if (arr !== null) {
                                                                                                        let fi = arr.findIndex(x => x.uid === batch.uid)
                                                                                                        if (fi !== -1) {
                                                                                                            if (arr[fi].unit === 1) {
                                                                                                                if (arr.length === 1) {
                                                                                                                    t[selectedItemRef].challan_info[i].returned_item_data = null
                                                                                                                    t[selectedItemRef].challan_info[i].returned_quantity = 0
                                                                                                                } else {
                                                                                                                    t[selectedItemRef].challan_info[i].returned_item_data.splice(fi, 1)
                                                                                                                    t[selectedItemRef].challan_info[i].returned_quantity -= 1
                                                                                                                }
                                                                                                            } else {
                                                                                                                t[selectedItemRef].challan_info[i].returned_item_data[fi].unit -= 1
                                                                                                                t[selectedItemRef].challan_info[i].returned_quantity -= 1
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                    setSalesReturnItemArray(t)
                                                                                                }}>−</button>
                                                                                            <div class="">
                                                                                                {obj.returned_item_data !== null && obj.returned_item_data.find(x => x.uid === batch.uid)?.unit || 0}
                                                                                            </div>
                                                                                            <button
                                                                                                class="btn btn-outline-success btn-sm rounded-circle fs-1 px-5 py-1"
                                                                                                onClick={() => {
                                                                                                    let t = salesReturnItemArray.map((x) => ({ ...x }))
                                                                                                    let arr = t[selectedItemRef].challan_info[i].returned_item_data

                                                                                                    let fi = arr ? arr.findIndex(x => x.uid === batch.uid) : -1

                                                                                                    let max = batch.unit - (b?.unit || 0)
                                                                                                    let cu = fi === -1 ? 0 : obj.returned_item_data[fi].unit
                                                                                                    if (cu >= max) {
                                                                                                        swal("Oops", `${b ? `For ${batch.batch_reference} out of ${batch.unit} units, ${b.unit} has been returned, Maximum ${max} unit is available for return` : "Maximum unit reached"}`, "warning")
                                                                                                        return
                                                                                                    }

                                                                                                    if (arr === null) {
                                                                                                        t[selectedItemRef].challan_info[i].returned_item_data = [{ ...batch, unit: 1 }]
                                                                                                        t[selectedItemRef].challan_info[i].returned_quantity = 1
                                                                                                    } else {
                                                                                                        if (fi !== -1) {

                                                                                                            t[selectedItemRef].challan_info[i].returned_item_data[fi].unit += 1
                                                                                                            t[selectedItemRef].challan_info[i].returned_quantity += 1
                                                                                                        }
                                                                                                        else {
                                                                                                            t[selectedItemRef].challan_info[i].returned_item_data.push({ ...batch, unit: 1 })
                                                                                                            t[selectedItemRef].challan_info[i].returned_quantity += 1
                                                                                                        }
                                                                                                    }
                                                                                                    setSalesReturnItemArray(t)
                                                                                                }}>+</button>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })
                                                                        }
                                                                    </div>

                                                                </div>
                                                                :
                                                                obj.challan_expanded &&
                                                                <div className="mb-3">
                                                                    <div className="fw-bold fs-6 text-primary mb-2">Choose Quantity</div>
                                                                    <div className="col-6 px-3 text-center d-flex gap-2">
                                                                        <input
                                                                            type="number"
                                                                            className="form-control form-control-solid px-2"
                                                                            min={0}
                                                                            value={obj.returned_quantity.toString()}
                                                                            onChange={(e) => {
                                                                                let val = e.target.value === '' ? 0 : parseFloat(e.target.value)

                                                                                let item = relatedSalesReturns.find(x => x.item_id === salesReturnItemArray[selectedItemRef].item_id)
                                                                                let challan = item?.challan_info.find(x => x.dc_id === obj.dc_id)

                                                                                if (val > obj.unit - challan?.returned_quantity || 0) {
                                                                                    swal("Oops", `${challan ? `Maximum ${obj.unit - challan.returned_quantity} unit is available for return` : "Maximum unit reached"}`, "warning")
                                                                                    return
                                                                                }

                                                                                let t = salesReturnItemArray.map((x) => ({ ...x }))
                                                                                t[selectedItemRef].challan_info[i].returned_quantity = val
                                                                                setSalesReturnItemArray(t)
                                                                            }}
                                                                        />
                                                                        {
                                                                            (() => {
                                                                                let item = relatedSalesReturns.find(x => x.item_id === salesReturnItemArray[selectedItemRef].item_id)
                                                                                let challan = item?.challan_info.find(x => x.dc_id === obj.dc_id)
                                                                                if (challan) {
                                                                                    return <span className="badge badge-danger fs-1">{challan.returned_quantity}</span>
                                                                                }
                                                                            })()
                                                                        }
                                                                    </div>
                                                                </div>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex-lg-row-fluid">
                        <div className="card-body">
                            <div className="fs-1 text-gray-600">
                                Return Quantity: {selectedItemRef !== null && salesReturnItemArray[selectedItemRef].challan_info.reduce((p, o) => { return p + o.returned_quantity }, 0)}
                            </div>
                        </div>
                    </div>
                    <button className="btn btn-outline-info rounded" onClick={() => { handleConfigureItemModalClose() }}>Confirm</button>
                </Modal.Footer>
            </Modal >

        </>
    );
};

export default EditSalesReturn;
