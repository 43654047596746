import React, { useState, useEffect } from 'react';

import swal from 'sweetalert';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { Link, Redirect, useHistory } from 'react-router-dom';
import CryptoJS from 'crypto-js'
import axios from 'axios';

import Select from 'react-select';

import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import { useTheme } from "./theme-provider.js"
import './MonthPicker.css';

export const GstSettings = () => {
	const [state, setstate] = useState(2);
	const { theme, dropdownStyleDark } = useTheme()

	const [GSTModalShow, setGSTModalShow] = useState(false);
	const [DefaultTaxModalShow, setDefaultTaxModalShow] = useState(false);
	const [OnlineFilingModalShow, setOnlineFilingModalShow] = useState(false);
	const [AccountSelectionList, setAccountSelectionList] = useState([]);

	const [Registered_date, setRegistered_date] = useState("");
	const [gst_id, setgst_id] = useState("");
	const [gst_status, setgst_status] = useState("");
	const [LegalName, setLegalName] = useState("");
	const [TradeName, setTradeName] = useState("");
	const [GstIN, setGstIN] = useState("");
	const [Reverse_charge, setReverse_charge] = useState(false);
	const [Import, setImport] = useState(false);
	const [Digital_service, setDigital_service] = useState(false);
	const [Tracking_account, setTracking_account] = useState("");
	const [Online_filing_settings, setOnline_filing_settings] = useState({ "GstIN_Username": "", "Reporting_Period": "Monthly", "Return_from": "", "Gst_Filing_Approval": "", "UserType": "Individual User", "UserValue": [] });
	const [Default_tax_preference, setDefault_tax_preference] = useState({ "Inter_State_Tax": "", "Intra_State_Tax": "" });
	const ReportingPeriodlist = ["Monthly"];
	const UserTypelist = ["Individual User", "Group User"];
	const [UserValuelist, setUserValuelist] = useState([]);
	const [StatusModalShow, setStatusModalShow] = useState(false);

	const [defaultGstModalShow, setDefaultGstModalShow] = useState(false);


	const [GstDetails, setGstDetails] = useState([]);
	const [Userlist, setUserlist] = useState([]);
	const [RoleList, setRoleList] = useState([]);

	const [branchList, setBranchlist] = useState([])

	const [gstEwayBillConfigStatusList, setGstEwayBillConfigStatusList] = useState([])

	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

	const [TaxGrouplist, setTaxGrouplist] = useState([{ label: "Agriculture", value: "Agriculture" }, { label: "Construction", value: "Construction" }, { label: "Education", value: "Education" }, { label: "Engineering", value: "Engineering" }, { label: "Entertainment", value: "Entertainment" }])
	const [Taxlist, setTaxlist] = useState([{ label: "English", value: "English" }]);
	const [selectedDate, setSelectedDate] = useState(new Date("2014-08-18T21:11:54"));

	const [ewayBillConfigModalShow, setEwayBillConfigModalShow] = useState(false)
	const [configUsername, setConfigUsername] = useState("")
	const [configPassword, setConfigPassword] = useState("")
	const [configGstinUid, setConfigGstinUid] = useState("")
	const [configGstinNumber, setConfigGstinNumber] = useState("")
	const [ewayBillConfigSubmitButtonState, setEwayBillConfigSubmitButtonState] = useState(false)

	const history = useHistory()

	var page_name = "gst-settings";

	const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	async function getgstdetails() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-gst-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "organization_id": organization_id, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();

		if (body.data.length > 0) {
			setGstDetails(body.data)
		}
	}

	async function getuserdetails() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-user-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();

		if (body.length > 0) {
			var temp_dropdown = [];
			body.map((obj) => {
				if (obj.log_status === "Active") {
					var temp_obj = {};
					temp_obj.label = obj.name;
					temp_obj.value = obj.user_id;
					temp_dropdown.push(temp_obj);
				}
			})

			setUserlist(temp_dropdown);
		}
	}

	async function getroledetails() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-roles`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": process.env.module_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();

		if (body.length > 0) {
			var temp_dropdown = [];
			body.map((obj) => {
				if (obj.log_status === "Active") {
					var temp_obj = {};
					temp_obj.label = obj.user_role;
					//temp_obj.value = obj.user_role_id;
					temp_obj.value = obj.user_role;
					temp_dropdown.push(temp_obj);
				}
			})
			setRoleList(temp_dropdown);
		}
	}

	async function gettaxdetails() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-regular-tax`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		var temp_dropdown = [];
		if (body.operation == "success") {
			if (body.data.length > 0) {
				body.data.map((obj) => {
					var temp_obj = {};
					temp_obj.label = obj.tax_name;
					temp_obj.value = obj.tax_id;
					temp_dropdown.push(temp_obj);
				})
				setTaxlist(temp_dropdown);
			}
		}
	}

	async function gettaxgroupdetails() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/tax-group-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		var temp_dropdown = [];
		if (body.operation == "success") {
			if (body.data.length > 0) {
				body.data.map((obj) => {
					var temp_obj = {};
					temp_obj.label = obj.tax_group_name;
					temp_obj.value = obj.tax_group_id;
					temp_dropdown.push(temp_obj);
				})
				setTaxGrouplist(temp_dropdown);
			}
		}
	}

	async function selectiveaccounthead() {
		let arr = [
			{ "category_name": "Expense", "headArray": [] },
		]
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/selective-account-head`, {
			method: 'POST', body: JSON.stringify({ "organization_id": organization_id, "headRequest": arr, "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
		}
		);
		const body = await response.json();
		//console.log(body)

		if (body.operation === "success") {
			let temp_dropdown = [];
			body.data.map((obj) => {
				var temp_obj = {};
				temp_obj.label = obj.category_name;
				let temp_dropdown1 = [];
				obj.headArray.map((obj1) => {
					var temp_obj1 = {};
					temp_obj1.label = obj1.name;
					temp_obj1.value = obj1.hid;

					temp_dropdown1.push(temp_obj1);
				})
				temp_obj.options = temp_dropdown1;
				temp_dropdown.push(temp_obj);
			})
			setAccountSelectionList(temp_dropdown);
		}
	}

	async function getGstEwayBillConfigStatus() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-e-way-bill-config-status-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		// console.log(body)
		if (body.length != 0) {
			setGstEwayBillConfigStatusList(body.data)
		}
	}

	async function getBranchDetails() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-branches`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		// console.log(body)

		if (body.operation == "success") {
			setBranchlist(body.data);
		}
		else {
			throw "branch list api error"
		}
	}

	useEffect(() => {
		if ((read || write) && (user.email && visibility)) {
			let p1 = getuserdetails();
			let p2 = getroledetails();
			let p3 = gettaxdetails();
			let p4 = gettaxgroupdetails();
			let p5 = selectiveaccounthead();
			let p6 = getgstdetails();
			let p7 = getGstEwayBillConfigStatus();
			let p8 = getBranchDetails();
			Promise.all([p1, p2.p3, p4, p5, p6, p7, p8])
				.then(() => {
					setstate(1);
					console.log("all apis done")
				})
				.catch((err) => {
					setstate(0);
					console.log(err)
				})
		}
	}, [user, visibility, read, write]);

	// const handleDateChange = (date) => {
	// 	console.log(date)
	// 	setSelectedDate(date);
	// };

	const submitGstDetails = (e) => {
		e.preventDefault();

		var values = { "page_name": page_name, "id": gst_id, "GstIN": GstIN, "LegalName": LegalName, "TradeName": TradeName, "Registered_date": Registered_date, "Reverse_charge": Reverse_charge, "Digital_service": Digital_service, "Import": Import, "Tracking_account": Tracking_account, "Online_filing_settings": Online_filing_settings, "Default_tax_preference": Default_tax_preference, "organization_id": organization_id };

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		var reg = /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/;
		if (!GstIN.match(reg)) {
			swal("Warning!", "Incorrect format for GstIn!", "Warning").then((value) => {

			});
			return false;

		}
		setMainSubmitButtonState(true);
		if (gst_id == "") {
			axios.post(`${process.env.REACT_APP_SERVER_URL}/save-gst-details`, values, headers).then((res) => {
				setMainSubmitButtonState(false)
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Gst details saved successfully!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});
		}
		else {
			axios.post(`${process.env.REACT_APP_SERVER_URL}/update-gst-details`, values, headers).then((res) => {
				setMainSubmitButtonState(false)
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Gst details updated successfully!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});
		}
	}

	const submitStatus = (unique_id, add_status) => {
		var id = id;
		setstate(2);
		var values = { "id": unique_id, "add_status": add_status, "organization_id": organization_id, "page_name": page_name };
		setMainSubmitButtonState(true)
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		axios.post(`${process.env.REACT_APP_SERVER_URL}/update-gst-status`, values, headers).then((res) => {
			setMainSubmitButtonState(false)
			if (res.data && res.data.operation == "success") {
				window.location.reload();
				swal("Great!", "Status changed successfully!", "success").then((value) => {
					window.location.reload();
				});
			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		});
	}

	const updateDefaultGst = (unique_id) => {
		setMainSubmitButtonState(true)
		const values = { "id": unique_id, "organization_id": organization_id, "page_name": page_name };
		const headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		axios.post(`${process.env.REACT_APP_SERVER_URL}/update-default-gst`, values, headers).then((res) => {
			setMainSubmitButtonState(false)
			setDefaultGstModalShow(false)
			if (res.data && res.data.operation == "success") {
				swal("Great!", "Status changed successfully!", "success").then(() => {
					window.location.reload();
				});
			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		});
	}

	const EditGstDetails = (id) => {
		for (let i = 0; i < GstDetails.length; i++) {
			if (id == GstDetails[i].unique_id) {
				setgst_id(id);
				setGstIN(GstDetails[i].GstIn);
				setRegistered_date(moment(GstDetails[i].Registered_date).format("yyyy-MM-DD"));
				setLegalName(GstDetails[i].legal_name);
				setTradeName(GstDetails[i].trade_name);
				console.log(GstDetails[i].Digital_service)
				if (GstDetails[i].Digital_service == 1) {
					setDigital_service(true);
				}
				if (GstDetails[i].Reverse_Charge == 1) {
					setReverse_charge(true);
				}
				if (GstDetails[i].Import == 1) {
					setImport(true);
				}
				if (GstDetails[i].Default_tax_preferences !== "") {
					setDefault_tax_preference(JSON.parse(GstDetails[i].Default_tax_preferences));
				}
				if (GstDetails[i].online_filing_settings !== "") {
					setOnline_filing_settings(JSON.parse(GstDetails[i].online_filing_settings));
				}
				//console.log(AccountSelectionList);
				//console.log(AccountSelectionList.filter(option => (option.value == GstDetails[i].Tracking_account)));
				if (AccountSelectionList.length > 0 && AccountSelectionList[0].options.filter(option => (option.value == GstDetails[i].Tracking_account)).length > 0) {
					setTracking_account({ label: AccountSelectionList[0].options.filter(option => (option.value == GstDetails[i].Tracking_account))[0].label, value: GstDetails[i].Tracking_account });
				}
			}
		}
	}

	const CreateGstDetails = (id) => {
		setgst_id("");
		setGstIN("");
		setRegistered_date("");
		setLegalName("");
		setTradeName("");
		setDigital_service(false);
		setReverse_charge(false);
		setImport(false);
		setDefault_tax_preference({});
		setOnline_filing_settings({});
		setTracking_account("");
	}

	const UserValueLoad = (value) => {
		if (value === "Individual User") {
			setUserValuelist(Userlist);
		}
		else {
			setUserValuelist(RoleList);
		}
	}

	const ValueLoad = (value) => {
		let json = Online_filing_settings;
		json.UserType = "Individual User";
		setUserValuelist(Userlist);
		setOnline_filing_settings(json);
	}

	const eWayBillConfigSubmit = () => {

		if (configUsername == "") {
			swal("Alert!", "Username cannot be empty", "warning")
			return
		}
		if (configPassword == "") {
			swal("Alert!", "Password cannot be empty", "warning")
			return
		}

		// let encrypted = CryptoJS.AES.encrypt(configPassword, process.env.REACT_APP_GSTIN_CONFIG_KEY).toString();
		// let decrypted = (CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_GSTIN_CONFIG_KEY)).toString(CryptoJS.enc.Utf8);

		// console.log(encrypted,decrypted)

		var object = {}

		object.username = configUsername
		object.password = CryptoJS.AES.encrypt(configPassword, process.env.REACT_APP_GSTIN_CONFIG_KEY).toString()
		object.gstin_uid = configGstinUid
		object.gstin_number = configGstinNumber

		object.organization_id = organization_id
		object.page_name = page_name;

		// console.log(object);
		setEwayBillConfigSubmitButtonState(true)

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		axios.post(`${process.env.REACT_APP_SERVER_URL}/add-e-way-bill-config`, object, headers).then((res) => {
			setEwayBillConfigSubmitButtonState(false)
			if (res.data.operation === "success") {
				swal("Great!", `E Way Bill configuration saved successfully!`, "success").then(() => history.go(0))
				handleEwayBillConfigModalClose()
				getGstEwayBillConfigStatus()
			}
			else {
				swal("Oops!", res.data.message, "error");
			}
		})
			.catch(function (error) {
				console.log(error)
			});
	}

	const handleEwayBillConfigModalClose = () => {
		setEwayBillConfigModalShow(false)

		setConfigUsername("")
		setConfigPassword("")
		setConfigGstinUid("")
		setConfigGstinNumber("")
		setEwayBillConfigSubmitButtonState(false)
	}

	return (
		<>
			<div>
				<div className="d-flex flex-stack mb-5">
					<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">GSTIN Settings</h1>
					<div className="d-flex align-items-center gap-2 gap-lg-3">
						<button onClick={() => { CreateGstDetails(); setGSTModalShow(true); }} className="btn btn-sm fw-bold btn-primary">New GSTIN</button>
					</div>
				</div>
				<div>
					{
						state == 1 ?
							<div className="row g-6 g-xl-9">
								{
									GstDetails.length > 0 && GstDetails.map((obj, index) => {
										return (
											<div className="col-lg-6" key={index}>
												<div className="card card-flush h-lg-100">
													<div className="card-header mt-6">
														<div className="card-title flex-column">
															<h3 className="fw-bolder mb-1">{obj.GstIn}</h3>
															<div className="d-flex gap-2">
																{obj.add_status == "Active" ? <span className="badge badge-light-success me-auto rounded">Active</span> : (obj.add_status == "Stopped" ? <span className="badge badge-light-danger me-auto rounded">Stopped</span> : <span className="badge badge-light-danger me-auto rounded">Deactivated</span>)}
																{obj.default_gstin == 1 && <span className="badge badge-light-primary me-auto rounded">Default</span>}
															</div>
														</div>
														<div className="card-toolbar">
															<div className="dropdown">
																{
																	write &&
																	<>
																		<button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-bars px-0"></i></button>
																		<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
																			<li className="py-1"><a onClick={(e) => { EditGstDetails(obj.unique_id); setGSTModalShow(true); }} className="dropdown-item cursor-pointer">Edit Details</a></li>
																			{obj.default_gstin == 0 && <li className="py-1"><a onClick={(e) => { setgst_id(obj.unique_id); setDefaultGstModalShow(true); }} className="dropdown-item cursor-pointer">Set as Default GST</a></li>}
																			{obj.add_status == "Active" ? <li className="py-1"><a onClick={(e) => { setgst_id(obj.unique_id); setgst_status(obj.add_status); setStatusModalShow(true); }} className="dropdown-item cursor-pointer">Marks as {gst_status == "active" ? <>inactive</> : <>active</>}</a></li> : <li><a onClick={(e) => { submitStatus(obj.unique_id, obj.add_status) }} className="dropdown-item cursor-pointer">Marks as inactive</a></li>}
																			<li className="py-1"><a onClick={(e) => { EditGstDetails(obj.unique_id); setOnlineFilingModalShow(true); }} className="dropdown-item cursor-pointer">Online filing settings</a></li>
																			<li className="py-1"><a onClick={(e) => { EditGstDetails(obj.unique_id); setDefaultTaxModalShow(true); }} className="dropdown-item cursor-pointer">Default tax preference</a></li>
																			<li className="py-1"><a onClick={(e) => { setEwayBillConfigModalShow(true); setConfigGstinUid(obj.unique_id); setConfigUsername(GstDetails.find(x => x.unique_id === obj.unique_id).online_filing_settings ? JSON.parse(GstDetails.find(x => x.unique_id === obj.unique_id).online_filing_settings)?.GstIN_Username || '' : ''); setConfigGstinNumber(obj.GstIn); }} className="dropdown-item cursor-pointer">Manage E Way Bill Configuration</a></li>
																		</ul>
																	</>
																}
															</div>
														</div>
													</div>
													<div className="d-flex flex-wrap">
														<div className="card-body p-9">
															<div className="fs-6 d-flex justify-content-between my-4">
																<div className="fw-bold">Business Legal Name</div>
																<div>{obj.legal_name}</div>
															</div>
															<div className="separator separator-dashed"></div>
															<div className="fs-6 d-flex justify-content-between my-4">
																<div className="fw-bold">Business Trade Name</div>
																<div>{obj.trade_name}</div>
															</div>
															<div className="separator separator-dashed"></div>
															<div className="fs-6 d-flex justify-content-between my-4">
																<div className="fw-bold">Registered Date</div>
																<div>{moment(obj.Registered_date).format('ll')}</div>
															</div>
															<div className="separator separator-dashed"></div>
															<div className="fs-6 d-flex justify-content-between my-4">
																<div className="fw-bold">Branches</div>
																<div>{obj.branch_list.length ? branchList.filter(x => obj.branch_list.includes(x.branch_id)).map(y => y.branch_name).join(', ') : 'N/A'}</div>
															</div>
															<div className="separator separator-dashed"></div>
															<div className="fs-6 d-flex justify-content-between my-4">
																<div className="fw-bold">E Way Bill Config Status</div>
																<div className="symbol-group symbol-hover">
																	<div className={`badge badge-light-${gstEwayBillConfigStatusList.find(x => x.gstin_uid == obj.unique_id) ? gstEwayBillConfigStatusList.find(x => x.gstin_uid == obj.unique_id).status === "VERIFIED" ? "success" : "warning" : "danger"} rounded`}>
																		{gstEwayBillConfigStatusList.find(x => x.gstin_uid == obj.unique_id) == undefined ? "Not Connected" : gstEwayBillConfigStatusList.find(x => x.gstin_uid == obj.unique_id).status.toLowerCase().replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										);
									})
								}
							</div>
							:
							state == 2 ?
								<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
								:
								<div className="flex-lg-row-fluid me-lg-15">
									<div className="card card-flush pt-3 mb-5 mb-lg-10">

										<div className="card-header">
											<div className="card-title">
												<h2 className="fw-bolder">GSTIN List</h2>

											</div>
										</div>
										<div className="card-body pt-0">
											<div className="text-gray-500 fw-bold fs-5 mb-5"></div>
											<div className="d-flex align-items-center p-3 mb-2">
											</div>
											<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
												<div className="d-flex flex-stack flex-grow-1">
													<div className="fw-bold">
														<h4 className="text-gray-800 fw-bolder">No Data Available</h4>
														<div className="fs-6 text-gray-600">
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
					}
				</div>
			</div>
			<Modal show={DefaultTaxModalShow} onHide={() => { setDefaultTaxModalShow() }} size="lg" centered>
				<Modal.Header>
					<Modal.Title>Default Tax Preference</Modal.Title>
					<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setDefaultTaxModalShow(false) }}>
						<span className="svg-icon svg-icon-1">
							<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
									<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
									<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
								</g>
							</svg>
						</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
						<div className="row mb-5">
							<div className="col-md-12 fv-row">
								<label className="required fs-5 fw-bold mb-2">Intra State Tax Rate</label>
								<Select
									styles={theme == 'dark' ? dropdownStyleDark : {}}
									name="category"
									id="category"
									options={TaxGrouplist}
									onChange={(event) => {
										Default_tax_preference.Intra_State_Tax = event.value;
									}}
									value={TaxGrouplist.filter(option => option.value == Default_tax_preference.Intra_State_Tax).length != 0 && TaxGrouplist.filter(option => option.value == Default_tax_preference.Intra_State_Tax)[0]}
									placeholder="--Select tax group--"
								/>
							</div>
						</div>
						<div className="row mb-5">
							<div className="col-md-12 fv-row">
								<label className="required fs-5 fw-bold mb-2">Inter State Tax Rate</label>
								<Select

									styles={theme == 'dark' ? dropdownStyleDark : {}}
									name="category"
									id="category"
									options={Taxlist}
									onChange={(event) => {
										Default_tax_preference.Inter_State_Tax = event.value;
									}}
									value={Taxlist.filter(option => option.value == Default_tax_preference.Inter_State_Tax).length != 0 && Taxlist.filter(option => option.value == Default_tax_preference.Inter_State_Tax)[0]}
									placeholder="--Select tax--"
								/>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
					{write && <form >
						<button type="button" onClick={(e) => { submitGstDetails(e) }} className="btn btn-primary" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">Update</span>
							<span className="indicator-progress">Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
					</form>}
				</Modal.Footer>
			</Modal>

			<Modal show={OnlineFilingModalShow} onHide={() => setOnlineFilingModalShow(false)} size="lg" centered>
				<Modal.Header>
					<Modal.Title>Online Filing Settings</Modal.Title>
					<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setOnlineFilingModalShow(false) }}>
						<span className="svg-icon svg-icon-1">
							<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
									<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
									<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
								</g>
							</svg>
						</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className='container'>
						<div className="d-flex justify-content-between mb-5">
							<div className="col-3 my-auto">
								<label className="required fs-5 mb-2">GSTIN Username</label>
							</div>
							<div className='col-9 my-auto'>
								<input type="text" className="form-control form-control-solid" placeholder="" name="Organization-name" value={Online_filing_settings.GstIN_Username}
									onChange={(e) => {
										setOnline_filing_settings({ ...Online_filing_settings, GstIN_Username: e.target.value });
									}} />
							</div>
						</div>
						<div className="d-flex justify-content-between mb-5">
							<div className="col-3 my-auto">
								<label className="required fs-5 mb-2">Reporting Period</label>
							</div>
							<div className='col-9 my-auto'>
								<Select
									styles={theme == 'dark' ? dropdownStyleDark : {}}
									options={ReportingPeriodlist.map(x => { return { label: x, value: x } })}
									onChange={(e) => {
										setOnline_filing_settings({ ...Online_filing_settings, Reporting_Period: e.value });
									}}
									value={ReportingPeriodlist.map(x => { return { label: x, value: x } }).find(option => option.value == Online_filing_settings.Reporting_Period)}
								/>
							</div>
						</div>
						<div className="d-flex justify-content-between mb-5">
							<div className="col-3 my-auto">
								<label className="required fs-5 mb-2">Tax Return From</label>
							</div>
							<div className='col-9 my-auto'>
								<input type="month" className="month-input" onChange={(e) => {
									setOnline_filing_settings({ ...Online_filing_settings, Return_from: e.target.value });
								}} value={Online_filing_settings.Return_from} />
							</div>
						</div>
						<div className="row mb-6">
							<label className="fs-5 fw-bold mb-2">GST Filing Approval</label>
							<div className="col-lg-8 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
								<div className="d-flex align-items-center mt-3">
									<label className="form-check form-check-custom form-check-inline form-check-solid me-5 is-valid">
										<input className="form-check-input" type="checkbox" checked={Online_filing_settings.Gst_Filing_Approval}
											onChange={(e) => {
												ValueLoad(e.target.checked);
												setOnline_filing_settings({ ...Online_filing_settings, Gst_Filing_Approval: e.target.checked, UserType: "Individual User", UserValue: [] });
											}} />
										<span className="fw-semibold ps-2 fs-6">Enable approval process for filing GST Returns</span>
									</label>
								</div>
								<div className="fv-plugins-message-container invalid-feedback"></div></div>
						</div>
						{Online_filing_settings.Gst_Filing_Approval === true &&
							<>
								<div className="d-flex justify-content-between mb-5">
									<div className="col-3 my-auto">
										<label className="required fs-5 mb-2">User Type</label>
									</div>
									<div className='col-9 my-auto'>
										<Select
											styles={theme == 'dark' ? dropdownStyleDark : {}}
											options={UserTypelist.map(x => { return { label: x, value: x } })}
											onChange={(event) => {
												UserValueLoad(event.value);
												setOnline_filing_settings({ ...Online_filing_settings, UserType: event.value, UserValue: [] })
											}}
											value={UserTypelist.map(x => { return { label: x, value: x } }).find(option => option.value == Online_filing_settings.UserType)}
										/>
									</div>
								</div>
								<div className="d-flex justify-content-between mb-5">
									<div className="col-3 my-auto">
										<label className="required fs-5 mb-2">{Online_filing_settings.UserType === "Individual User" ? <>User List</> : <>Group List</>}</label>
									</div>
									<div className='col-9 my-auto'>
										<Select
											styles={theme == 'dark' ? dropdownStyleDark : {}}
											isMulti
											options={UserValuelist}
											onChange={(event) => {
												setOnline_filing_settings({ ...Online_filing_settings, UserValue: event.map(a => a.value) });
											}}
											value={Online_filing_settings.UserValue.map((e) => { return { label: e, value: e } })}
											placeholder={`--Select--`}
										/>
									</div>
								</div>
							</>
						}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn btn-secondary" onClick={(e) => { setOnlineFilingModalShow(false) }}>Close</button>
					{write == 1 && <form >
						<button type="button" onClick={(e) => { submitGstDetails(e) }} className="btn btn-primary" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">Update</span>
							<span className="indicator-progress">Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						</button>
					</form>
					}
				</Modal.Footer>
			</Modal>

			<Modal show={GSTModalShow} onHide={() => { setGSTModalShow() }} size="lg" centered>
				<Modal.Header>
					<Modal.Title>{gst_id == "" ? <>New GstIn</> : <>Update GstIn Details</>}</Modal.Title>
					<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setGSTModalShow(false) }}>
						<span className="svg-icon svg-icon-1">
							<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
									<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
									<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
								</g>
							</svg>
						</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="modal-body py-10 px-lg-17">
						<div className="row mb-5">
							<div className="col-md-12 fv-row">
								<label className="required fs-5 fw-bold mb-2">GSTIN</label>
								<input id="subcategory_name" type="text" className="form-control form-control-solid" placeholder="" name="Organization-name" value={GstIN} onChange={(e) => { setGstIN(e.target.value) }} />
							</div>
						</div>
						<div className="row mb-5">
							<div className="col-md-12 fv-row">
								<label className="fs-5 fw-bold mb-2">Business Legal Name</label>
								<input id="subcategory_name" type="text" className="form-control form-control-solid" placeholder="" name="Organization-name" value={LegalName} onChange={(e) => { setLegalName(e.target.value) }} />
							</div>
						</div>
						<div className="row mb-5">
							<div className="col-md-12 fv-row">
								<label className="fs-5 fw-bold mb-2">Business Trade Name</label>
								<input id="subcategory_name" type="text" className="form-control form-control-solid" placeholder="" name="Organization-name" value={TradeName} onChange={(e) => { setTradeName(e.target.value) }} />
							</div>
						</div>
						<div className="row mb-5">
							<div className="col-md-6 fv-row">
								<label className="fs-5 fw-bold mb-2">Registered Date</label>
								<input type="date" name="Expense_date" className="form-control" id="Registered_date" placeholder="Sales Start Date" value={Registered_date}
									onChange={(e) => {
										setRegistered_date(e.target.value);
									}}
								/>
							</div>
						</div>
						<div className="row mb-6">
							<label className="fs-5 fw-bold mb-2">Reverse Charge</label>
							<div className="col-lg-8 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
								<div className="d-flex align-items-center mt-3">
									<label className="form-check form-check-custom form-check-inline form-check-solid me-5 is-valid">
										<input className="form-check-input" name="communication[]" type="checkbox" checked={Reverse_charge} onChange={(e) => {
											setReverse_charge(e.target.checked);
										}} />
										<span className="fw-semibold ps-2 fs-6">Enable Reverse Charge in Sales transactions</span>
									</label>
								</div>
								<div className="fv-plugins-message-container invalid-feedback"></div></div>
						</div>
						<div className="row mb-6">
							<label className="fs-5 fw-bold mb-2">Import / Export</label>
							<div className="col-lg-8 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
								<div className="d-flex align-items-center mt-3">
									<label className="form-check form-check-custom form-check-inline form-check-solid me-5 is-valid">
										<input className="form-check-input" name="communication[]" type="checkbox" checked={Import} onChange={(e) => {
											setImport(e.target.checked);
										}} />
										<span className="fw-semibold ps-2 fs-6">My business is involved in SEZ / Overseas Trading</span>
									</label>
								</div>
								<div className="fv-plugins-message-container invalid-feedback"></div></div>
						</div>
						{Import === true &&
							<div className="row mb-6">
								<label className="fs-5 fw-bold mb-2">Tracking Account</label>
								<Select
									styles={theme == 'dark' ? dropdownStyleDark : {}}
									options={AccountSelectionList}
									onChange={(val) => {
										//console.log(val); 
										setTracking_account(val);
									}}
									value={Tracking_account}
									placeholder="--Select Account--"
								/>
								<span className="fw-semibold ps-2 fs-6"></span>

								<div className="fv-plugins-message-container invalid-feedback"></div>
							</div>}
						<div className="row mb-6">
							<label className="fs-5 fw-bold mb-2">Digital Services</label>
							<div className="col-lg-8 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
								<div className="d-flex align-items-center mt-3">
									<label className="form-check form-check-custom form-check-inline form-check-solid me-5 is-valid">
										<input className="form-check-input" name="communication[]" type="checkbox" checked={Digital_service} onChange={(e) => {
											setDigital_service(e.target.checked);
										}} />
										<span className="fw-semibold ps-2 fs-6">Track sale of digital services to overseas customers</span>
									</label>
								</div>
								<div className="fv-plugins-message-container invalid-feedback"></div></div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					{write && <button onClick={(e) => { submitGstDetails(e) }} type="submit" id="kt_modal_new_address_submit" className="btn btn-primary rounded" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState}>
						<span className="indicator-label">{gst_id == "" ? <>Save</> : <>Update</>}</span>
						<span className="indicator-progress">Please wait...
							<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
					</button>}
				</Modal.Footer>
			</Modal>

			<Modal show={StatusModalShow} onHide={() => { setStatusModalShow(false) }} centered>
				<Modal.Header>
					<Modal.Title>Change Status</Modal.Title>
					<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setStatusModalShow(false) }}>
						<span className="svg-icon svg-icon-1">
							<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
									<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
									<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
								</g>
							</svg>
						</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="container">
						<h4>
							Are you sure you want to mark as {gst_status === "Active" ? <>Inactive</> : <>Active</>}?
						</h4>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-secondary btn-sm rounded" onClick={() => { setStatusModalShow(false) }}>Close</button>
					{
						write == 1 &&
						<>
							{gst_status === "Active" ?
								<button type="button" onClick={(e) => { submitStatus(gst_id, gst_status) }} className="btn btn-danger" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">Inactive</span>
									<span className="indicator-progress">Please wait...
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
								:
								<button type="button" onClick={(e) => { submitStatus(gst_id, gst_status) }} className="btn btn-success" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">Active</span>
									<span className="indicator-progress">Please wait...
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
							}
						</>
					}
				</Modal.Footer>
			</Modal>

			<Modal show={defaultGstModalShow} onHide={() => { setDefaultGstModalShow(false) }} centered>
				<Modal.Header>
					<Modal.Title>Change Default GST</Modal.Title>
					<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setDefaultGstModalShow(false) }}>
						<span className="svg-icon svg-icon-1">
							<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
									<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
									<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
								</g>
							</svg>
						</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="container">
						<h4>
							Are you sure you want to mark it as Default GST?
						</h4>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-secondary btn-sm rounded" onClick={() => { setDefaultGstModalShow(false) }}>No</button>
					{
						write == 1 &&
						<button type="button" onClick={(e) => { updateDefaultGst(gst_id) }} className="btn btn-danger" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">Yes</span>
							<span className="indicator-progress">Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						</button>
					}
				</Modal.Footer>
			</Modal>

			<Modal show={ewayBillConfigModalShow} onHide={() => { handleEwayBillConfigModalClose(); }} centered>
				<Modal.Header>
					<Modal.Title>Manage Configuration</Modal.Title>
					<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleEwayBillConfigModalClose(); }}>
						<span className="svg-icon svg-icon-1">
							<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
									<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
									<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
								</g>
							</svg>
						</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="container">
						<div className='row gx-0 mb-2'>
							<div className='col-md-6'><label className='fs-5 fw-bold required'>Username</label></div>
							<div className='col-md-6'><input type="text" className="form-control form-control-solid" value={configUsername} onChange={(e) => { setConfigUsername(e.target.value.trim()) }} /></div>
						</div>
						<div className='row gx-0 mb-2'>
							<div className='col-md-6'><label className='fs-5 fw-bold required'>Password</label></div>
							<div className='col-md-6'><input type="password" className="form-control form-control-solid" autoComplete="new-password" value={configPassword} onChange={(e) => { setConfigPassword(e.target.value) }} /></div>
						</div>
						<div className='row gx-0 mb-2'>
							<div className='col-md-6'><label className='fs-5 fw-bold required'>GSTIN</label></div>
							<div className='col-md-6'><input type="text" className="form-control form-control-solid" value={configGstinNumber} readOnly disabled /></div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-danger btn-sm rounded" onClick={() => { handleEwayBillConfigModalClose() }}>Close</button>
					{
						write &&
						<button className="btn btn-primary btn-sm" data-kt-indicator={ewayBillConfigSubmitButtonState ? "on" : "off"} disabled={ewayBillConfigSubmitButtonState} onClick={() => { eWayBillConfigSubmit() }}>
							<span className="indicator-label">Save</span>
							<span className="indicator-progress">Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						</button>
					}
				</Modal.Footer>
			</Modal>
		</>);

};

export default GstSettings;
