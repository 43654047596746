import React from 'react'

const PageError = ({ children, message="Some error occured", showReloadMsg=true }) => {

    return (
        <div className="card card-flush my-5 shadow-sm border-0">
            <div className="card-body p-5">
                <div className="bg-light-secondary border p-13 rounded-3">
                    <div className="d-flex flex-wrap justify-content-between align-items-center gap-5">
                        <div className="fw-bold mw-700px">
                            <h4 className="text-gray-800 fw-bolder mb-3">{message}</h4>
                            {showReloadMsg && <div className="fs-6 text-gray-600">Please reload the page.</div>}
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageError
