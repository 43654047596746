import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';

import { useTheme } from "./theme-provider.js"
import { useAuthData } from "./auth-provider.js"
import { setCookie, getCookie } from '../common_component/cookie.js';


function AsideNavbar(props) {
	const [access, setaccess] = useState([]);
	const [selected, setSelected] = useState("");
	const [levelOne, setLevelOne] = useState("");
	const [levelTwo, setLevelTwo] = useState("");
	const [levelThree, setLevelThree] = useState("");
	const [view, setview] = useState("");
	const [MobileView, setMobileView] = useState(false);
	const [Admin_flag, setAdmin_flag] = useState("");

	const [isCollapsed, setIsCollapsed] = useState(false)
	const history = useHistory()
	const { theme } = useTheme()

	var page_name = "dashboard";

	const { rolePermissionDetails } = useAuthData(page_name)

	useEffect(() => {
		if (rolePermissionDetails !== null) {
			if (
				navigator.userAgent.match(/Android/i)
				|| navigator.userAgent.match(/webOS/i)
				|| navigator.userAgent.match(/iPhone/i)
				|| navigator.userAgent.match(/iPad/i)
				|| navigator.userAgent.match(/iPod/i)
				|| navigator.userAgent.match(/BlackBerry/i)
				|| navigator.userAgent.match(/Windows Phone/i)
			) {
				setMobileView(true);
			}
			else {
				setMobileView(false)
			}
			let original_url = window.location.href.split("/");
			original_url = original_url[3];
			original_url = original_url.replace(/[^\w -_]/g, '');

			if (original_url == "") {
				original_url = "dashboard";
			}
			configureView(rolePermissionDetails, original_url)
			setaccess(rolePermissionDetails);
		}
	}, [rolePermissionDetails])

	const configureView = (access, original_url) => {

		if (access.filter(option => option.key == 'Admin View').length > 0) {
			setAdmin_flag(access.filter(option => option.key == 'Admin View')[0].visibility);
		}

		let index1 = 0;
		for (let i = 0; i < access.length; i++) {
			let component = access[i].inside_components;
			for (let j = 0; j < component.length; j++) {
				if (component[j].hasOwnProperty(original_url)) {
					index1 = i;
					setview(access[index1].key);
				}
				else if (component[j].hasOwnProperty("key")) {
					let sub_inside_components = component[j].inside_components
					for (let k = 0; k < sub_inside_components.length; k++) {
						if (sub_inside_components[k].hasOwnProperty(original_url)) {
							index1 = i;
							setview(access[index1].key);
						}
						else if (sub_inside_components[k].hasOwnProperty("key")) {
							//console.log(sub_inside_components[k]);
							for (let l = 0; l < sub_inside_components[k].inside_components.length; l++) {
								if (sub_inside_components[k].inside_components[l].hasOwnProperty(original_url)) {
									index1 = i;
									setview(access[index1].key);
								}
							}
						}
					}
				}
			}
		}
	}

	let optionMap = {
		"/account-overview": [],
		"/add-user": [],
		"/show-user": ["/update-user"],

		"/roles": ["/update-roles"],
		"/profiles": ["/update-profile"],

		// items
		"/item-list": ["/add-item", "/item-images", "/edit-item", "/item-price-information", "/additional-item-price-information", "/item-stock-information", "/edit-item-stock-information"],
		"/brands": [],
		"/category-list": [],
		"/subcategory-list": [],
		"/bulk-update-item-price-information": [],
		"/item-stock-approval": [],

		"/customer": ["/customer-list", "/edit-customer-details", "/customer-address-details", "/view-customer-details"],

		// sales
		"/customer_order": ["/create-customer-order", "/view-customer-order", "/edit-customer-order", "/customer-order-list"],
		"/recieve_payment": ["/new-payment-received", "/payment-received-list", "/edit-payment-received"],
		"/credit-note-list": ["/create-credit-note", "/credit-note-list", "/edit-credit-note"],
		"/sales_return": ["/create-sales-return", "/view-sales-return-details", "/edit-sales-return", "/sales-return-list"],

		// purchaces
		"/vendor": ["/add-vendor", "/list-vendor", "/update-vendor", "/vendor-address-details"],
		"/manufacturer": ["/add-manufacturer", "/list-manufacturer", "/update-manufacturer", "/manufacturer-address-details"],
		"/purchase_order": ["/create-purchase-order", "/edit-purchase-order", "/view-purchase-order", "/purchase-order-list", "/purchase-order-approval-preview", "/purchase-order-approval-email"],
		"/goods_receive": ["/create-goods-receive", "/edit-goods-receive", "/view-goods-receive", "/goods-received-list"],
		"/Bill": ["/create-bill", "/edit-bill", "/view-bill", "/bill-list"],
		"/make_payment": ["/make-payment", "/edit-payment-details", "/payment-list"],

		"/list-manufacturer": ["/update-manufacturer"],
		"/list-vendor": ["/update-vendor"],
		"/list-creditor": ["/update-creditor"],

		"/add-category": [],
		"/category-list": [],
		"/subcategory-list": [],

		"/add-subcategory": [],
		"/subcategory-list": [],

		"/creditor-purchase-order-list": ["/edit-creditor-purchase-order", "/view-creditor-purchase-order", "/creditor-purchase-order-approval-email"],
		"/creditor-purchase-order-approver-list": ["/creditor-purchase-order-approval-preview"],

		// "/add-item": [],

		"none": ["/login"]
	}

	let not_expand = [{ key: "customer", url: "customer-list", display_text: "Customer" }, { key: "goods_receive", url: "goods-received-list", display_text: "Goods Receive" }, { key: "purchase_order", url: "purchase-order-list", display_text: "Purchase Order" }, { key: "Bill", url: "bill-list", display_text: "Bills" }, { key: "manufacturer", url: "list-manufacturer", display_text: "Manufacturer" }, { key: "vendor", url: "list-vendor", display_text: "Vendor" }, { key: "customer_order", url: "customer-order-list", display_text: "Sales Order" }, { key: "recieve_payment", url: "payment-received-list", display_text: "Receive Payment " }, { key: "make_payment", url: "payment-list", display_text: "Make Payment" }, { key: "credit_note", url: "credit-note-list", display_text: "Credit Note" }, { key: "sales_return", url: "sales-return-list", display_text: "Sales Return" }]

	let not_show_section = ["all_report"]

	let not_show = [
		"account-overview",
		"add-item",
		"edit-payment-received",
		"edit-expense-details",
		"add-customer",
		"edit-purchase-order",
		"view-purchase-order",
		"purchase-order-approval-email",
		"purchase-order-approval-preview",
		"edit-goods-receive",
		"view-goods-receive",
		"edit-bill",
		"view-bill",
		"item-images",
		"additional-item-price-information",
		"update-roles",
		"update-profile",
		"edit-item",
		"edit-dealer-order",
		"view-dealer-order",
		"dealer-order-approval-preview",
		"process-dealer-order-invoice",
		"edit-dealer-invoice",
		"view-dealer-invoice",
		"dealer-invoice-approval-preview",
		"dealer-warehouse-processing",
		"add-account",
		"edit-pjp-plan",
		"dso-check-out",
		"view-visit-log",
		"update-vendor",
		"update-dealer",
		"dealer-address-details",
		"update-manufacturer",
		"update-creditor",
		"edit-customer-details",
		"view-customer-details",
		"customer-address-details",
		"item-price-information",
		"item-stock-information",
		"edit-item-stock-information",
		"creditor-address-details",
		"configure-ecommerce-item-info",
		"view-customer-order",
		"customer-order-approval-preview",
		"edit-customer-order",
		"process-customer-order-invoice",
		"edit-customer-invoice",
		"view-customer-invoice",
		"customer-invoice-approval-preview",
		"customer-warehouse-processing",
		"create-customer-delivery-challan",
		"view-customer-delivery-challan",
		"customer-invoice-approval-list",
		"add-expense",
		"create-customer-invoice",
		"create-sales-return",
		"edit-sales-return",
		"view-sales-return-details",
	]


	///to be improved and removed
	useEffect(() => {
		let id = setInterval(() => {
			let currentUrl = window.location.href
			let t = currentUrl.split("/")

			t = (/\d/.test(t[t.length - 1])) ? t[t.length - 2] : t[t.length - 1]
			t = t.replace(/[^\w -]/g, '');

			let targetOption = undefined;
			Object.keys(optionMap).forEach((elem) => {
				if (optionMap[elem].includes("/" + t)) {
					targetOption = elem
				}
			})

			if (targetOption == undefined) {
				setSelected(t)
			}
			else {
				if (targetOption != "none") {
					setSelected(targetOption.replace("/", ""))
				}
			}

		}, 500)
		return () => {
			clearInterval(id)
		}
	}, [])

	//Function for custom link as per item name in the json
	const customLink = (option) => {
		return `/${option}`
	}
	//Function to format Menu Header and submenu items 
	const formatString = (str) => {

		let formatted_string;

		if (str.includes("payments-made")) {
			formatted_string = "Payments Made";
		} else if (str.includes("payment-received-report")) {
			formatted_string = "Payment Received Report";
		} else if (str.includes("payment_received")) {
			formatted_string = "Payment Received";
		} else if (str.includes("item-bill-report")) {
			formatted_string = "Item Bill Report";
		} else if (str.includes("item-gr-report")) {
			formatted_string = "Item GR Report";
		} else if (str.includes("bill-report")) {
			formatted_string = "Bill Report";
		} else if (str.includes("purchase-report")) {
			formatted_string = "Purchase Report";
		} else if (str.includes("item-sales-report")) {
			formatted_string = "Item Sales Report";
		} else if (str.includes("customer-sales-report")) {
			formatted_string = "Customer Sales Report";
		} else if (str.includes("organization-expense-report")) {
			formatted_string = "Organization Expense";
		} else if (str.includes("employee-expense-report")) {
			formatted_string = "Employee Expense";
		} else if (str.includes("manufacturer-expense-report")) {
			formatted_string = "Manufacturer Expense";
		} else if (str.includes("vendor-expense-report")) {
			formatted_string = "Vendor Expense";
		} else if (str.includes("customer-expense-report")) {
			formatted_string = "Customer Expense";
		} else if (str.includes("expense-summary")) {
			formatted_string = "Expense Summary";
		} else if (str.includes("expense_reports")) {
			formatted_string = "Expense Report";
		} else if (str.includes("account-overview")) {
			formatted_string = "Manage Account";
		} else if (str === "expense") {
			formatted_string = "Expenses";
		} else if (str === "item") {
			formatted_string = "Items";
		} else if (str.includes("recurring-expense-list")) {
			formatted_string = "Recurring Expense";
		} else if (str.includes("expense-list")) {
			formatted_string = "Regular Expense";
		} else if (str.includes("customer_invoice")) {
			formatted_string = "Invoice";
		} else if (str.includes("customer-delivery-challan-list")) {
			formatted_string = "Delivery Challan";
		} else if (str.includes("customer-warehouse-processing-list")) {
			formatted_string = "Warehouse Process";
		} else if (str.includes("create-customer-invoice")) {
			formatted_string = "Create Invoice";
		} else if (str.includes("customer-invoice-list")) {
			formatted_string = "Invoice List";
		} else if (str.includes("item-stock-approval")) {
			formatted_string = "Stock Approval";
		} else if (str.includes("subcategory-list")) {
			formatted_string = "Sub Category";
		} else if (str.includes("item-list")) {
			formatted_string = "Item";
		} else if (str === "category-list") {
			formatted_string = "Category";
		} else if (str.includes("brands")) {
			formatted_string = "Brand";
		} else if (str.includes("bulk-update-item-price-information")) {
			formatted_string = "Bulk Update Item Price";
		} else if (str.toLowerCase().includes("gst")) {
			formatted_string = str.replace(/gst/g, "GST").replace(/[-_]/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
		} else {
			formatted_string = str
				.replace(/[-_]/g, ' ')
				.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
		}

		return formatted_string;
	}

	//Function to render custom icon as per Menu Title in the json
	const customIcon = (menuName) => {
		//console.log(menuName)
		if (menuName === "admin-dashboard" || menuName === "dashboard") {
			return (
				<span className="svg-icon svg-icon-2 fs-2">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M6.5 11C8.98528 11 11 8.98528 11 6.5C11 4.01472 8.98528 2 6.5 2C4.01472 2 2 4.01472 2 6.5C2 8.98528 4.01472 11 6.5 11Z" fill="currentColor" />
						<path opacity="0.3" d="M13 6.5C13 4 15 2 17.5 2C20 2 22 4 22 6.5C22 9 20 11 17.5 11C15 11 13 9 13 6.5ZM6.5 22C9 22 11 20 11 17.5C11 15 9 13 6.5 13C4 13 2 15 2 17.5C2 20 4 22 6.5 22ZM17.5 22C20 22 22 20 22 17.5C22 15 20 13 17.5 13C15 13 13 15 13 17.5C13 20 15 22 17.5 22Z" fill="currentColor" />
					</svg>
				</span>
			)
		}
		if (menuName === "Sales") {
			return (
				<i className="fa-solid fa-money-bill-trend-up fs-3 p-0"></i>
			)
		}
		if (menuName === "Purchases") {
			return (
				<i className="fa-solid fa-shop fs-3 p-0"></i>
			)
		}

		else if (menuName === "organization") {
			return (
				<span className="svg-icon svg-icon-2 fs-2">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="currentColor"></path>
						<path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="currentColor"></path>
					</svg>
				</span>
			)
		}
		else if (menuName === "item") {
			return (
				<span className="svg-icon svg-icon-2 fs-2">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M21 10H13V11C13 11.6 12.6 12 12 12C11.4 12 11 11.6 11 11V10H3C2.4 10 2 10.4 2 11V13H22V11C22 10.4 21.6 10 21 10Z" fill="currentColor"></path>
						<path opacity="0.3" d="M12 12C11.4 12 11 11.6 11 11V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V11C13 11.6 12.6 12 12 12Z" fill="currentColor"></path>
						<path opacity="0.3" d="M18.1 21H5.9C5.4 21 4.9 20.6 4.8 20.1L3 13H21L19.2 20.1C19.1 20.6 18.6 21 18.1 21ZM13 18V15C13 14.4 12.6 14 12 14C11.4 14 11 14.4 11 15V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18ZM17 18V15C17 14.4 16.6 14 16 14C15.4 14 15 14.4 15 15V18C15 18.6 15.4 19 16 19C16.6 19 17 18.6 17 18ZM9 18V15C9 14.4 8.6 14 8 14C7.4 14 7 14.4 7 15V18C7 18.6 7.4 19 8 19C8.6 19 9 18.6 9 18Z" fill="currentColor"></path>
					</svg>
				</span>
			)
		}
		else if (menuName === "settings") {
			return (
				<span className="svg-icon svg-icon-2 fs-2">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor" />
						<path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor" />
					</svg>
				</span>
			)
		}
		else if (menuName === "approval & notification") {
			return (
				<i className="bi bi-bell fs-3 p-0"></i>
			)
		}
		else if (menuName === "tax & currency") {
			return (
				<i className="bi bi-cash fs-3 p-0"></i>
			)
		}
		else if (menuName === "banking") {
			return (
				<i className="bi bi-bank fs-3 p-0"></i>
			)
		}
		else if (menuName === "account-overview") {
			return (
				<span className="svg-icon svg-icon-2 fs-2">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z" fill="currentColor"></path>
						<rect opacity="0.3" x="8" y="3" width="8" height="8" rx="4" fill="currentColor"></rect>
					</svg>
				</span>
			)
		}
		else if (menuName === "user") {
			return (
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z" fill="currentColor"></path>
					<rect opacity="0.3" x="8" y="3" width="8" height="8" rx="4" fill="currentColor"></rect>
				</svg>
			)
		}
		else if (menuName === "project") {
			return (
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M18 21.6C16.6 20.4 9.1 20.3 6.3 21.2C5.7 21.4 5.1 21.2 4.7 20.8L2 18C4.2 15.8 10.8 15.1 15.8 15.8C16.2 18.3 17 20.5 18 21.6ZM18.8 2.8C18.4 2.4 17.8 2.20001 17.2 2.40001C14.4 3.30001 6.9 3.2 5.5 2C6.8 3.3 7.4 5.5 7.7 7.7C9 7.9 10.3 8 11.7 8C15.8 8 19.8 7.2 21.5 5.5L18.8 2.8Z" fill="currentColor"></path>
					<path opacity="0.3" d="M21.2 17.3C21.4 17.9 21.2 18.5 20.8 18.9L18 21.6C15.8 19.4 15.1 12.8 15.8 7.8C18.3 7.4 20.4 6.70001 21.5 5.60001C20.4 7.00001 20.2 14.5 21.2 17.3ZM8 11.7C8 9 7.7 4.2 5.5 2L2.8 4.8C2.4 5.2 2.2 5.80001 2.4 6.40001C2.7 7.40001 3.00001 9.2 3.10001 11.7C3.10001 15.5 2.40001 17.6 2.10001 18C3.20001 16.9 5.3 16.2 7.8 15.8C8 14.2 8 12.7 8 11.7Z" fill="currentColor"></path>
				</svg>
			)
		}
		else if (menuName === "category-list") {
			return (
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path opacity="0.3" d="M7 20.5L2 17.6V11.8L7 8.90002L12 11.8V17.6L7 20.5ZM21 20.8V18.5L19 17.3L17 18.5V20.8L19 22L21 20.8Z" fill="currentColor"></path>
					<path d="M22 14.1V6L15 2L8 6V14.1L15 18.2L22 14.1Z" fill="currentColor"></path>
				</svg>
			)
		}
		else if (menuName === "role") {
			return (
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="currentColor"></path>
					<path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="currentColor"></path>
				</svg>
			)
		}
		else if (menuName === "profile") {
			return (
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="currentColor"></path>
					<path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="currentColor"></path>
				</svg>
			)
		}
		else if (menuName === "show-organizations") {
			return (
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="currentColor"></path>
					<path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="currentColor"></path>
				</svg>
			)
		}
		else if (menuName === "payments" || menuName === "currency") {
			return (
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path opacity="0.3" d="M20.859 12.596L17.736 13.596L10.388 20.944C10.2915 21.0406 10.1769 21.1172 10.0508 21.1695C9.9247 21.2218 9.78953 21.2486 9.65302 21.2486C9.5165 21.2486 9.3813 21.2218 9.25519 21.1695C9.12907 21.1172 9.01449 21.0406 8.918 20.944L2.29999 14.3229C2.10543 14.1278 1.99619 13.8635 1.99619 13.588C1.99619 13.3124 2.10543 13.0481 2.29999 12.853L11.853 3.29999C11.9495 3.20341 12.0641 3.12679 12.1902 3.07452C12.3163 3.02225 12.4515 2.9953 12.588 2.9953C12.7245 2.9953 12.8597 3.02225 12.9858 3.07452C13.1119 3.12679 13.2265 3.20341 13.323 3.29999L21.199 11.176C21.3036 11.2791 21.3797 11.4075 21.4201 11.5486C21.4605 11.6898 21.4637 11.8391 21.4295 11.9819C21.3953 12.1247 21.3249 12.2562 21.2249 12.3638C21.125 12.4714 20.9989 12.5514 20.859 12.596Z" fill="currentColor"></path>
					<path d="M14.8 10.184C14.7447 10.1843 14.6895 10.1796 14.635 10.1699L5.816 8.69997C5.55436 8.65634 5.32077 8.51055 5.16661 8.29469C5.01246 8.07884 4.95035 7.8106 4.99397 7.54897C5.0376 7.28733 5.18339 7.05371 5.39925 6.89955C5.6151 6.7454 5.88334 6.68332 6.14498 6.72694L14.963 8.19692C15.2112 8.23733 15.435 8.36982 15.59 8.56789C15.7449 8.76596 15.8195 9.01502 15.7989 9.26564C15.7784 9.51626 15.6642 9.75001 15.479 9.92018C15.2939 10.0904 15.0514 10.1846 14.8 10.184ZM17 18.6229C17 19.0281 17.0985 19.4272 17.287 19.7859C17.4755 20.1446 17.7484 20.4521 18.0821 20.6819C18.4158 20.9117 18.8004 21.0571 19.2027 21.1052C19.605 21.1534 20.0131 21.103 20.3916 20.9585C20.7702 20.814 21.1079 20.5797 21.3758 20.2757C21.6437 19.9716 21.8336 19.607 21.9293 19.2133C22.025 18.8195 22.0235 18.4085 21.925 18.0154C21.8266 17.6223 21.634 17.259 21.364 16.9569L19.843 15.257C19.7999 15.2085 19.7471 15.1697 19.688 15.1432C19.6289 15.1167 19.5648 15.1029 19.5 15.1029C19.4352 15.1029 19.3711 15.1167 19.312 15.1432C19.2529 15.1697 19.2001 15.2085 19.157 15.257L17.636 16.9569C17.2254 17.4146 16.9988 18.0081 17 18.6229ZM10.388 20.9409L17.736 13.5929H1.99999C1.99921 13.7291 2.02532 13.8643 2.0768 13.9904C2.12828 14.1165 2.2041 14.2311 2.29997 14.3279L8.91399 20.9409C9.01055 21.0381 9.12539 21.1152 9.25188 21.1679C9.37836 21.2205 9.51399 21.2476 9.65099 21.2476C9.78798 21.2476 9.92361 21.2205 10.0501 21.1679C10.1766 21.1152 10.2914 21.0381 10.388 20.9409Z" fill="currentColor"></path>
				</svg>
			)
		}
		else if (menuName === "customer" || menuName === "dealer" || menuName === "vendor") {
			return (
				<span className="svg-icon svg-icon-2 fs-2">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z" fill='currentColor'></path>
						<rect opacity="0.3" x="8" y="3" width="8" height="8" rx="4" fill='currentColor'></rect>
					</svg>
				</span>
			)
		}
		else if (menuName === "purchase_order" || menuName === "customer_order" || menuName === "customer_invoice" || menuName === "PO_creditorss") {
			return (
				<i className="fa-solid fa-file-invoice fs-3 p-0"></i>
			)
		}
		else if (menuName === "approvals") {
			return (
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M13 5.91517C15.8 6.41517 18 8.81519 18 11.8152C18 12.5152 17.9 13.2152 17.6 13.9152L20.1 15.3152C20.6 15.6152 21.4 15.4152 21.6 14.8152C21.9 13.9152 22.1 12.9152 22.1 11.8152C22.1 7.01519 18.8 3.11521 14.3 2.01521C13.7 1.91521 13.1 2.31521 13.1 3.01521V5.91517H13Z" fill="currentColor"></path>
					<path opacity="0.3" d="M19.1 17.0152C19.7 17.3152 19.8 18.1152 19.3 18.5152C17.5 20.5152 14.9 21.7152 12 21.7152C9.1 21.7152 6.50001 20.5152 4.70001 18.5152C4.30001 18.0152 4.39999 17.3152 4.89999 17.0152L7.39999 15.6152C8.49999 16.9152 10.2 17.8152 12 17.8152C13.8 17.8152 15.5 17.0152 16.6 15.6152L19.1 17.0152ZM6.39999 13.9151C6.19999 13.2151 6 12.5152 6 11.8152C6 8.81517 8.2 6.41515 11 5.91515V3.01519C11 2.41519 10.4 1.91519 9.79999 2.01519C5.29999 3.01519 2 7.01517 2 11.8152C2 12.8152 2.2 13.8152 2.5 14.8152C2.7 15.4152 3.4 15.7152 4 15.3152L6.39999 13.9151Z" fill="currentColor"></path>
				</svg>
			)
		}
		else if (menuName === "tax") {
			return (
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M22 7H2V11H22V7Z" fill="currentColor"></path>
					<path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z" fill="currentColor"></path>
				</svg>
			)
		}
		else if (menuName === "settings") {
			return (
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="currentColor" />
					<path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="currentColor" />
				</svg>
			)
		}
		else if (menuName === "Finance File Tracker" || menuName === "reports") {
			return (
				<i className="fa-solid fa-file fs-3 p-0"></i>
			)
		}
		else if (menuName === "bank-report" || menuName === "bank" || menuName === "banking") {
			return (
				<i className="fa-solid fa-building-columns fs-3 p-0"></i>
			)
		}
		else if (menuName === "expense" || menuName === "bill_payment" || menuName === "recieve_payment" || menuName === "e_way_bill") {
			return (

				<i className="fa-solid fa-money-bill-1-wave fs-3 p-0"></i>
			)
		}
		else if (menuName === "e_way_bill" || menuName === "Bill") {
			return (

				<i className="fa-solid fa-money-bill-1-wave fs-3 p-0"></i>
			)
		}
		else {
			return (
				<span className="svg-icon svg-icon-2 fs-2">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M20 7H3C2.4 7 2 6.6 2 6V3C2 2.4 2.4 2 3 2H20C20.6 2 21 2.4 21 3V6C21 6.6 20.6 7 20 7ZM7 9H3C2.4 9 2 9.4 2 10V20C2 20.6 2.4 21 3 21H7C7.6 21 8 20.6 8 20V10C8 9.4 7.6 9 7 9Z" fill="currentColor"></path>
						<path opacity="0.3" d="M20 21H11C10.4 21 10 20.6 10 20V10C10 9.4 10.4 9 11 9H20C20.6 9 21 9.4 21 10V20C21 20.6 20.6 21 20 21Z" fill="currentColor"></path>
					</svg>
				</span>
			)
		}
	}


	const handleLvOne = (title) => {
		handleLvTwo("")
		if (title === levelOne) {
			setLevelOne("")
		}
		else {
			setLevelOne(title)
		}
	}
	const handleLvTwo = (title) => {
		handleLvThree("");
		if (title === levelTwo) {
			setLevelTwo("")
		}
		else {
			setLevelTwo(title)
		}
	}

	const handleLvThree = (title) => {
		if (title === levelThree) {
			setLevelThree("");
		}
		else {
			setLevelThree(title)
		}
	}

	useEffect(() => {
		if (access.length > 0) {
			access.map((obj) => {
				if (obj.hasOwnProperty("key")) {
					let tempArr1 = []
					obj.inside_components.forEach(x => {
						if (Object.keys(x)[0] === "key") {
							x.inside_components.forEach(y => {
								if (Object.keys(y)[0] === "key") {
									y.inside_components.forEach(z => tempArr1.push(Object.keys(z)[0]));
								}
								else {
									tempArr1.push(Object.keys(y)[0]);
								}
							})
						}
						else {
							tempArr1.push(Object.keys(x)[0])
						}
					})

					if (tempArr1.includes(selected)) {
						handleLvOne(obj.key)
					}
					//console.log(tempArr1)
				}
			})

			let tempArr2 = []
			access.forEach((obj) => {
				obj.inside_components.forEach(x => {
					if (Object.keys(x)[0] === "key") {
						tempArr2.push(x)
					}
				})
			})
			tempArr2.forEach((obj) => {
				obj.inside_components.forEach(y => {
					if (Object.keys(y)[0] === "key") {
						let arr = [];
						y.inside_components.forEach(z => arr.push(Object.keys(z)[0]));
						if (arr.includes(selected)) {
							handleLvTwo(obj.key)
						}
					}
					else {
						let a = obj.inside_components.map(y => Object.keys(y)[0])
						if (a.includes(selected)) {
							handleLvTwo(obj.key)
						}
					}
				})
			})

			let tempArr3 = []
			access.forEach((obj) => {
				obj.inside_components.forEach(x => {
					if (Object.keys(x)[0] === "key") {
						x.inside_components.forEach(y => {
							if (Object.keys(y)[0] === "key") {
								tempArr3.push(y);
							}
						})
					}
				})
			})
			tempArr3.forEach((obj) => {
				let a = obj.inside_components.map(y => Object.keys(y)[0]);
				if (a.includes(selected)) {
					handleLvThree(obj.key)
				}
			})
		}

	}, [access])

	const nav_select = (val) => {
		if (val == true) {
			configureView(access, "admin-dashboard")
			history.push("/admin-dashboard")
		}
		else {
			configureView(access, "dashboard")
			history.push("/dashboard")
		}
	}

	return (
		<>
			<div className={`h-aside-navbar ${isCollapsed ? "h-aside-navbar-collapse" : ""}`} style={{ transform: props.asideNavbarExpandMobile ? "translate(0px, 0px)" : undefined, backgroundColor: view === "Admin View" ? "#2d201e" : "#1e1e2d" }} ref={props.asideNavbarRef} tabIndex={-1} onBlur={() => { props.setAsideNavbarExpandMobile(false); }}>
				<div className="h-app-sidebar-logo px-6">
					<Link to="/">
						<img alt="Logo" src={theme === "dark" ? process.env.REACT_APP_DARK_LOGO : process.env.REACT_APP_LOGO} className={`${isCollapsed ? "h-20px" : "h-50px"}`} style={{ transition: "all 1s ease-in-out" }} />
					</Link>

					<div className={`h-app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate ${isCollapsed ? "active" : ""}`} onClick={() => { setIsCollapsed(!isCollapsed) }}>
						<span className="svg-icon svg-icon-2 rotate-180">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path opacity="0.5" d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z" fill="currentColor" />
								<path d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z" fill="currentColor" />
							</svg>
						</span>
					</div>
				</div>

				<div className="app-sidebar-menu flex-column-fluid" style={{ height: "70%", overflowY: "auto" }}>
					<div className="app-sidebar-wrapper hover-scroll-overlay-y">
						<div className="menu menu-column menu-rounded menu-sub-indention overflow-hidden px-3">
							{
								access.length > 0 && access.map((obj, index) => {

									let tempArr = []
									obj.inside_components.forEach(x => {
										if (Object.keys(x)[0] === "key") {
											x.inside_components.forEach(y => tempArr.push(Object.keys(y)[0]))
										}
										else {
											tempArr.push(Object.keys(x)[0])
										}
									})

									return (
										<React.Fragment key={index}>
											{
												(obj.hasOwnProperty("key") && obj.visibility == 1 && obj.key == view) &&
												<>
													<div className="menu-item pt-5">
														<div className="menu-content">
															<span className="menu-heading fw-bold text-uppercase text-nowrap fs-7">{formatString(obj.key)}</span>
														</div>
													</div>
													{
														obj.inside_components.length > 0 && obj.inside_components.map((obj1, index1) => {
															return (
																<React.Fragment key={index1}>
																	{
																		!not_show_section.includes(obj1.key) && obj1.hasOwnProperty("key") && not_expand.find(obj => obj.key === obj1.key) == undefined && obj1.visibility == 1 ?
																			<div className={`menu-item menu-accordion  ${(levelTwo === obj1.key) ? "hover show" : ""}`}>
																				<span className={`menu-link text-white-50 text-hover-white bg-active-light text-active-dark ${(selected === obj1.key) ? "active" : ""}`} onClick={() => { handleLvTwo(obj1.key) }}>
																					<span className="menu-icon mx-0">{customIcon(obj1.key)}</span>
																					<span className='menu-title ms-1 fs-4'>{formatString(obj1.key)}</span>
																					<span className="menu-arrow"></span>
																				</span>
																				<div className="menu-sub menu-sub-accordion menu-active-bg">

																					{
																						(!not_show_section.includes(obj1.key)) && (obj1.inside_components.length > 0) && obj1.inside_components.map((obj2, index2) => {
																							return (
																								<React.Fragment key={index2}>
																									{
																										(!not_show_section.includes(obj2.key)) && (obj2.hasOwnProperty("key") && not_expand.find(obj => obj.key === obj2.key) == undefined && obj2.visibility == 1) ?
																											<div className={`menu-item menu-accordion ${(levelThree === obj2.key) ? "hover show" : ""}`}>
																												<span className={`menu-link text-white-50 text-hover-white bg-active-light text-active-dark ${(selected === obj2.key) ? "active" : ""} `} onClick={() => { handleLvThree(obj2.key) }}>
																													<span className="menu-bullet"><span className="bullet bullet-dot"></span></span>
																													<span className='menu-title fs-5'>{formatString(obj2.key)}</span>
																													<span className="menu-arrow"></span>
																												</span>
																												<div className={`menu-sub menu-sub-accordion ${(levelThree === obj2.key) ? "show" : ""}`}>
																													{
																														obj2.inside_components.length > 0 && obj2.inside_components.map((obj3, index3) => {
																															return (
																																<React.Fragment key={index3}>
																																	{
																																		(Object.values(obj3)[0] == 1) && (!not_show.includes(Object.keys(obj3)[0])) &&
																																		<Link to={customLink(Object.keys(obj3)[0])}>
																																			<div className="menu-item">
																																				<span className={`menu-link text-white-50 text-hover-white bg-active-light text-active-dark ${(selected === Object.keys(obj3)[0]) ? "active" : ""}`} onClick={() => { setSelected(Object.keys(obj3)[0]) }}>
																																					<span className="menu-bullet"><span className="bullet bullet-dot"></span></span>
																																					<span className='menu-title fs-5'>{formatString(Object.keys(obj3)[0])}</span>
																																				</span>
																																			</div>
																																		</Link>
																																	}
																																</React.Fragment>
																															)
																														})
																													}
																												</div>
																											</div>
																											:
																											<>
																												{
																													(Object.values(obj2)[0] == 1) && (!not_show.includes(Object.keys(obj2)[0])) ?
																														<Link to={customLink(Object.keys(obj2)[0])}>
																															<div className="menu-item">
																																<span className={`menu-link text-white-50 text-hover-white bg-active-light text-active-dark ${(selected === Object.keys(obj2)[0]) ? "active" : ""}`} onClick={() => { setSelected(Object.keys(obj2)[0]) }}>
																																	<span className="menu-bullet"><span className="bullet bullet-dot"></span></span>
																																	<span className='menu-title fs-4'>{formatString(Object.keys(obj2)[0])}</span>
																																</span>
																															</div>
																														</Link>
																														:
																														not_expand.find(temp_obj => temp_obj.key === obj2.key) !== undefined &&
																														<Link to={customLink(not_expand.find(temp_obj => temp_obj.key === obj2.key).url)}>
																															<div className="menu-item">
																																<span className={`menu-link text-white-50 text-hover-white bg-active-light text-active-dark ${(selected === obj2.key) ? "active" : ""}`} onClick={() => { setSelected(obj2.key) }}>
																																	<span className="menu-bullet"><span className="bullet bullet-dot"></span></span>
																																	<span className='menu-title fs-4'>{not_expand.find(temp_obj => temp_obj.key === obj2.key).display_text}</span>
																																</span>
																															</div>
																														</Link>
																												}
																											</>
																									}
																								</React.Fragment>
																							)
																						})
																					}
																				</div>
																			</div>
																			:
																			((Object.values(obj1)[0] == 1) && (!not_show.includes(Object.keys(obj1)[0]))) ?
																				<Link to={customLink(Object.keys(obj1)[0])}>
																					<div className="menu-item">
																						<span className={`menu-link text-white-50 text-hover-white bg-active-light text-active-dark ${(selected === Object.keys(obj1)[0]) ? "active" : ""}`} onClick={() => { setSelected(Object.keys(obj1)[0]) }}>
																							<span className="menu-icon">{customIcon(Object.keys(obj1)[0])}</span>
																							<span className='menu-title fs-4'>{((Object.keys(obj1)[0] === "show-user") ? "Team" : ((Object.keys(obj1)[0] === "combo-item-list") ? "Combo Item List" : ((Object.keys(obj1)[0] === "support") ? "Send us Query" : ((Object.keys(obj1)[0] === "support-query-list") ? "Tickets" : formatString(Object.keys(obj1)[0])))))}</span>
																						</span>
																					</div>
																				</Link>
																				:
																				not_expand.find(temp_obj => temp_obj.key === obj1.key) !== undefined &&
																				<Link to={customLink(not_expand.find(temp_obj => temp_obj.key === obj1.key).url)}>
																					<div className="menu-item">
																						<span className={`menu-link text-white-50 text-hover-white bg-active-light text-active-dark ${(selected === obj1.key) ? "active" : ""}`} onClick={() => { setSelected(obj1.key) }}>
																							<span className="menu-icon">{customIcon(obj1.key)}</span>
																							<span className='menu-title fs-4'>{not_expand.find(temp_obj => temp_obj.key === obj1.key).display_text}</span>
																						</span>
																					</div>
																				</Link>
																	}
																</React.Fragment>
															)
														})
													}
												</>
											}
										</React.Fragment>
									)
								})
							}
						</div>
					</div>
				</div>

				<div className="app-sidebar-footer flex-column-auto p-3">
					{
						Admin_flag == 1 &&

						<button onClick={(e) => { nav_select(view == "Admin View" ? false : true); }} className="btn btn-light-primary w-100 text-nowrap mb-5" >
							{
								view == "Admin View" ?
									<>
										<span className="btn-icon"><i className="fa fa-arrow-left p-0 mb-1 fs-5"></i></span>
										<span className='btn-label fs-5 ms-3'>Dashboard</span>
									</>
									:
									<>
										<span className="btn-icon"><i className="fa-solid fa-gear p-0 mb-1 fs-5"></i></span>
										<span className='btn-label fs-5 ms-3'>Settings</span>
									</>
							}
						</button>

					}

					<button className="btn btn-light-primary w-100 text-nowrap"
						onClick={() => {
							setCookie("access_token", "", -1, true);
							setCookie("organization_id", "", -1, true);
							if (getCookie("access_token") == "" && getCookie("organization_id") == "") {
								history.push("/login")
							}
						}}
					>
						<span className="btn-icon"><i className="fa-solid fa-power-off fs-5 p-0"></i></span>
						<span className="btn-label fs-5 ms-3">Sign Out</span>
					</button>

				</div>
			</div>
		</>
	);
}
export default AsideNavbar;